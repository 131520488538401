import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DataTableComponent from '../../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import { getJobs } from '../../../service/api';
import Swal from 'sweetalert2';
import { getUserToken } from '../../../utils/auth';

const Jobs = () => {
	const [jobs, setJobs] = useState([]);

	const columns = [
		{
			name: 'Title',
			selector: (row) => row.title,
			sortable: true,
		},
		{
			name: 'Department',
			selector: (row) => row.department?.name,
			sortable: true,
		},
		{
			name: 'Province',
			selector: (row) => row.province?.name,
			sortable: true,
		},
		{
			name: 'Advertisement Number',
			selector: (row) => row.advertisementNumber,
			sortable: true,
		},
		{
			name: 'Employment Type',
			selector: (row) => row.employmentType,
			sortable: true,
		},
		{
			name: 'Employment Type',
			selector: (row) => row.employmentType,
			sortable: true,
		},
		{
			name: 'Approximate Salary',
			selector: (row) => row.approximateSalary,
			sortable: true,
		},
		{
			name: 'Job Opening Date',
			selector: (row) =>
				row?.jobOpeningDate
					? moment(row.jobOpeningDate).format('YYYY-MM-DD')
					: 'N/A',
			sortable: true,
		},
		{
			name: 'Last Date To Apply',
			selector: (row) => moment(row.lastDateToApply).format('YYYY-MM-DD'),
			sortable: true,
		},
		{
			name: 'Total Vacancies',
			selector: (row) => row.totalVacancies,
			sortable: true,
		},
		{
			name: 'Scale',
			selector: (row) => row.scale,
			sortable: true,
		},
		{
			name: 'Hiring Agency',
			selector: (row) => row.hiringAgency?.name,
			sortable: true,
		},
		{
			name: 'Newspaper',
			selector: (row) => row.newspaper?.name,
			sortable: true,
		},
		{
			name: 'Minimum Age',
			selector: (row) => row.minimumAge,
			sortable: true,
		},
		{
			name: 'Maximum Age',
			selector: (row) => row.maximumAge,
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<button
						to={`/admin/edit/${row._id}`}
						className="btn btn-outline-info text-nowrap"
						disabled
					>
						Update
					</button>
					<button
						className="btn btn-outline-danger p-1 m-1 text-nowrap"
						onClick={() => handleDelete(row._id)}
					>
						Delete
					</button>
				</div>
			),
			sortable: false,
		},
	];

	const handleDelete = async (id) => {
		const confirmed = await Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this job!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it',
			cancelButtonText: 'No, cancel',
			reverseButtons: true,
		});

		if (confirmed.isConfirmed) {
			try {
				const response = await getJobs({
					id,
					token: getUserToken(),
				});
				if (response.success) {
					const jobsList = jobs.filter((jobs) => jobs._id !== id);
					setJobs(jobsList);
					Swal.fire(
						'Deleted!',
						'The job has been deleted.',
						'Success',
					);
				}
			} catch (error) {
				console.log('Error deleting jobs:', error);
				Swal.fire(
					'Error',
					'An error occurred while deleting the jobs.',
					'error',
				);
			}
		} else if (confirmed.dismiss === Swal.DismissReason.cancel) {
			Swal.fire('Cancelled', 'The Pages deletion was cancelled.', 'info');
		}
	};

	useEffect(() => {
		const fetchJobs = async () => {
			const response = await getJobs();
			if (response.success) {
				setJobs(response.data);
			}
		};
		fetchJobs();
	}, []);

	return (
		<>
			<div className=" mt-5 mx-md-5 mx-3 card rounded-lg shadow-lg">
				<div className="card-header d-flex justify-content-between h3">
					<span className="d-md-block d-none"></span>
					<span className="text-primary">All Jobs</span>
					<Link
						to="/admin/jobs/add"
						className="btn btn-outline-primary"
					>
						Add Jobs
					</Link>
				</div>
				<div className="card-body">
					<div className="">
						{jobs && jobs.length > 0 ? (
							<DataTableComponent
								columns={columns}
								data={jobs}
								showSearch={true}
								searchKey="title"
							/>
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Jobs;

import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';

function DataTableComponent({
	columns,
	data,
	showSearch = false,
	searchKey,
}) {
	const [search, setSearch] = useState(null);

	const filteredData = useMemo(() => {
		return search
			? data.filter((item) =>
					item[searchKey]
						?.toString()
						.toLowerCase()
						.includes(search.toLowerCase()),
				)
			: data;
	}, [data, search]);

	return (
		<div className="mt-5">
			<DataTable
				columns={columns}
				data={filteredData}
				striped
				noDataComponent="Loading ..."
				pagination={data.length > 10}
				paginationPerPage={10}
				subHeader
				subHeaderComponent={
					showSearch ? (
						<input
							type="text"
							placeholder="Search"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							className="search-input border-1 border border-primary form-control-sm float-end"
						/>
					) : null
				}
			/>
		</div>
	);
}

export default DataTableComponent;

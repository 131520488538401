import React from 'react';
import PageForm from '../../../components/PageForm';

const AddPage = () => {
	return (
		<>
			<PageForm />
		</>
	);
};

export default AddPage;

import React, { useEffect, useState } from 'react';

import { EmploymentType } from '../utils/enum/employmentType.enum';
import { Gender } from '../utils/enum/gender.enum';
import * as Yup from 'yup';
import FormikForm from './FormikForm/FormikForm';
import {
	addJob,
	getHiringAgencyPages,
	getNewspaperPages,
	getPagesByLevel,
} from '../service/api';
import {
	Divisions,
	EducationLevels,
	MATRIC,
	INTERMEDIATE,
	BACHELORS_2_YEARS,
	DAE,
	Subjects,
} from '../utils/enum/education.enum';
import { PayScales, BPS, PPS, MP, G, SPPS } from '../utils/enum/scales.enum';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const JobForm = ({ job }) => {
	const [levelTwoPages, setLevelTwoPages] = useState([]);
	const [levelThreePages, setLevelThreePages] = useState([]);
	const [levelFourPages, setLevelFourPages] = useState([]);
	const [newspapers, setNewspapers] = useState([]);
	const [hiringAgencies, setHiringAgencies] = useState([]);
	const [selectedProvinceId, setSelectedProvinceId] = useState(null);
	const [selectedImages, setSelectedImages] = useState([]);
	const [selectedLevel, setSelectedLevel] = useState('');
	const [degreeOptions, setDegreeOptions] = useState([]);

	console.log(selectedLevel, selectedProvinceId);

	const navigate = useNavigate();

	const initialValues = {
		title: '',
		province: '',
		department: '',
		profession: '',
		cities: [],
		quotaBased: {
			speciallyAble: 0,
			transgender: 0,
			retireesChildren: 0,
			women: 0,
			minority: 0,
		},
		totalVacancies: '',
		scale: '',
		hiringAgency: null,
		newspaper: null,
		level: '',
		degree: [],
		division: '',
		requiredExperience: '',
		employmentType: '',
		minimumAge: '',
		maximumAge: '',
		gender: '',
		approximateSalary: '',
		jobOpeningDate: '',
		lastDateToApply: '',
		advertisementNumber: '',
		howToApply: '',
	};

	const validationSchema = Yup.object().shape({
		title: Yup.string().required('* Job Title is required'),
		province: Yup.string(),
		department: Yup.string().required('* Department is required'),
		profession: Yup.string(),
		cities: Yup.array().of(
			Yup.object().shape({
				name: Yup.string(),
				vacancies: Yup.string(),
			}),
		),
		totalVacancies: Yup.number()
			.nullable()
			.min(1, '* Total Vacancies must be at least 1')
			.integer('* Total Vacancies must be an integer'),
		hiringAgency: Yup.string().nullable(),
		newspaper: Yup.string().nullable(),
		requiredExperience: Yup.number()
			.nullable()
			.min(1, '* Experience must be at least 1 year')
			.max(20, '* Experience cannot exceed 20 years'),
		employmentType: Yup.string().nullable(),
		minimumAge: Yup.number().nullable(),
		maximumAge: Yup.number().nullable(),
		gender: Yup.string()
			.nullable()
			.oneOf(Object.values(Gender), 'Invalid Gender'),
		approximateSalary: Yup.number().nullable(),
		jonOpeningDate: Yup.date().nullable(),
		lastDateToApply: Yup.date().required(
			'* Last Date to Apply is required',
		),
		advertisementNumber: Yup.string().required(
			'* Advertisement Number is required',
		),
		advertisementImage: Yup.mixed(),
		howToApply: Yup.string().nullable(),
	});

	const formFields = [
		{
			type: 'text',
			label: '* Job title',
			name: 'title',
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Province',
			name: 'province',
			options: [
				...levelTwoPages
					.filter(
						(page) =>
							page.isProvince || page.slug === 'Federal-Jobs',
					)
					.map((province) => ({
						label: province.name,
						value: province._id,
					})),
			],
			setSelected: setSelectedProvinceId,
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: '* Department',
			name: 'department',
			options: [
				...levelThreePages
					.filter(
						(page) =>
							(page.levelTwoParent._id === selectedProvinceId &&
								page.isDepartment) ||
							page.levelTwoParent.slug === 'Armed-Forces-Jobs' ||
							page.levelTwoParent.slug ===
								'Service-Commission-Jobs' ||
							page.levelTwoParent.slug === 'Islamabad-Jobs',
					)
					.map((department) => ({
						label: department.name,
						value: department._id,
					})),
				...levelFourPages
					.filter(
						(page) =>
							(page.levelTwoParent._id === selectedProvinceId &&
								page.isDepartmentInCity) ||
							(page.levelTwoParent._id === selectedProvinceId &&
								page.isFederalDepartmentInProvince),
					)
					.map((department) => ({
						label: department.name,
						value: department._id,
					})),
			],
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Profession',
			name: 'profession',
			options: [
				...levelThreePages
					.filter((page) => page.isProfession)
					.map((profession) => ({
						label: profession.name,
						value: profession._id,
					})),
			],
			fieldSize: 'md',
		},
		{
			type: 'cities',
			label: 'Cities',
			name: 'cities',
			options: [
				...levelThreePages
					.filter((page) => {
						const isFederal = levelTwoPages.some(
							(page) =>
								page._id === selectedProvinceId &&
								page.slug === 'Federal-Jobs',
						);

						return isFederal
							? page.isCity
							: page.levelTwoParent._id === selectedProvinceId &&
									page.isCity;
					})
					.map((city) => ({
						label: city.name,
						value: city._id,
					})),
			],
		},
		{
			type: 'group',
			label: 'Quota Based',
			children: [
				[
					{
						type: 'number',
						label: 'Specially-Able',
						name: 'quotaBased.speciallyAble',
						min: 0,
						fieldSize: 'md',
					},
					{
						type: 'number',
						label: 'Transgender',
						name: 'quotaBased.transgender',
						min: 0,
						fieldSize: 'md',
					},
					{
						type: 'number',
						label: "Retiree's Children",
						name: 'quotaBased.retireesChildren',
						min: 0,
						fieldSize: 'md',
					},
					{
						type: 'number',
						label: 'Women',
						name: 'quotaBased.women',
						min: 0,
						fieldSize: 'md',
					},
					{
						type: 'number',
						label: 'Minority',
						name: 'quotaBased.minority',
						min: 0,
						fieldSize: 'md',
					},
				],
			],
		},
		{
			type: 'number',
			label: 'Total Vacancies',
			name: 'totalVacancies',
			min: 1,
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Scale',
			name: 'scale',
			options: [
				{
					label: PayScales.BPS,
					options: Object.entries(BPS).map(([key]) => ({
						label: BPS[key],
						value: BPS[key],
					})),
				},
				{
					label: PayScales.PPS,
					options: Object.entries(PPS).map(([key]) => ({
						label: PPS[key],
						value: PPS[key],
					})),
				},
				{
					label: PayScales.MP,
					options: Object.entries(MP).map(([key]) => ({
						label: MP[key],
						value: MP[key],
					})),
				},
				{
					label: PayScales.G,
					options: Object.entries(G).map(([key]) => ({
						label: G[key],
						value: G[key],
					})),
				},
				{
					label: PayScales.SPPS,
					options: Object.entries(SPPS).map(([key]) => ({
						label: SPPS[key],
						value: SPPS[key],
					})),
				},
				{
					label: PayScales.OTHERS,
					options: [{ label: 'Others', value: 'others' }],
				},
			],
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Hiring Agency',
			name: 'hiringAgency',
			options: [
				...hiringAgencies.map((hiringAgency) => ({
					label: hiringAgency.name,
					value: hiringAgency._id,
				})),
			],
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Newspaper',
			name: 'newspaper',
			options: [
				...newspapers.map((newspaper) => ({
					label: newspaper.name,
					value: newspaper._id,
				})),
			],
			fieldSize: 'md',
		},
		{
			type: 'group',
			label: 'Minimum Education',
			children: [
				[
					{
						type: 'select',
						label: 'Level',
						name: 'level',
						options: [
							...Object.keys(EducationLevels).map((key) => ({
								label: EducationLevels[key],
								value: EducationLevels[key],
							})),
						],
						setSelected: setSelectedLevel,
						fieldSize: 'md',
					},
					{
						type: 'multiselect',
						label: 'Degree',
						name: 'degree',
						options: degreeOptions,
						fieldSize: 'md',
					},
					{
						type: 'select',
						label: 'Division',
						name: 'division',
						options: [
							...Object.keys(Divisions).map((key) => ({
								label: Divisions[key],
								value: Divisions[key],
							})),
						],
						fieldSize: 'md',
					},
				],
			],
		},
		{
			type: 'select',
			label: 'Required Experience',
			name: 'requiredExperience',
			options: Array.from({ length: 20 }, (_, index) => ({
				value: index + 1,
				label: index + 1,
			})),
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Employment Type',
			name: 'employmentType',
			options: [
				...Object.keys(EmploymentType).map((key) => ({
					label: EmploymentType[key],
					value: EmploymentType[key],
				})),
			],
			fieldSize: 'md',
		},
		{ type: 'number', label: 'Minimum Age', name: 'minimumAge', min: 0 },
		{ type: 'number', label: 'Maximum Age', name: 'maximumAge', min: 0 },
		{
			type: 'select',
			label: 'Gender',
			name: 'gender',
			options: [
				...Object.values(Gender).map((value) => ({
					label: value,
					value: value,
				})),
			],
			fieldSize: 'md',
		},
		{
			type: 'number',
			label: 'Approximate Salary',
			name: 'approximateSalary',
			min: 0,
			fieldSize: 'md',
		},
		{
			type: 'date',
			label: 'Job Opening Date',
			name: 'jobOpeningDate',
			fieldSize: 'md',
		},
		{
			type: 'date',
			label: '* Last Date to Apply',
			name: 'lastDateToApply',
			fieldSize: 'md',
		},
		{
			type: 'text',
			label: '* Advertisement Number',
			name: 'advertisementNumber',
		},
		{ type: 'textarea', label: 'How to Apply', name: 'howToApply' },
		{
			type: 'file',
			label: 'Advertisement Image',
			name: 'advertisementImage',
			multiple: true,
			onChange: async (event) => {
				const newFiles = Array.from(event.target.files);

				newFiles.forEach((file) => {
					const reader = new FileReader();
					reader.onload = (e) => {
						setSelectedImages((prevImages) => [
							...prevImages,
							e.target.result,
						]);
					};
					reader.readAsDataURL(file);
				});
			},
		},
	];

	const onSubmit = async (values) => {
		const cleanData = (data) => {
			return Object.fromEntries(
				Object.entries(data).filter(
					([key, value]) =>
						value !== null && value !== undefined && value !== '',
				),
			);
		};

		const jobData = cleanData({
			...values,
			advertisementImage: selectedImages,
		});
		try {
			if (job) {
				// await updateAdmin(admin._id, values);
			} else {
				await addJob(jobData);
			}
			navigate('/admin/jobs');
		} catch (error) {
			const errorResponse = error.response.data;
			if (!errorResponse.success) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: errorResponse.message,
				});
			}
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [newspapersResponse, hiringAgenciesResponse] =
					await Promise.all([
						getNewspaperPages(),
						getHiringAgencyPages(),
					]);
				if (newspapersResponse.success) {
					setNewspapers(newspapersResponse.data);
				}

				if (hiringAgenciesResponse.success) {
					setHiringAgencies(hiringAgenciesResponse.data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (selectedLevel === 'Matric') {
			setDegreeOptions([
				{
					label: EducationLevels.MATRIC,
					options: Object.entries(MATRIC).map(([value, label]) => ({
						value,
						label,
					})),
				},
			]);
		} else if (selectedLevel === 'Intermediate') {
			setDegreeOptions([
				{
					label: EducationLevels.INTERMEDIATE,
					options: Object.entries(INTERMEDIATE).map(
						([value, label]) => ({ value, label }),
					),
				},
			]);
		} else if (selectedLevel === 'DAE') {
			setDegreeOptions([
				{
					label: EducationLevels.DAE,
					options: Object.entries(DAE).map(([value, label]) => ({
						value,
						label,
					})),
				},
			]);
		} else if (selectedLevel === 'Bachelors (2 Years)') {
			setDegreeOptions([
				{
					label: EducationLevels.BACHELORS_2_YEARS,
					options: Object.entries(BACHELORS_2_YEARS).map(
						([value, label]) => ({ value, label }),
					),
				},
			]);
		} else if (
			selectedLevel === 'Bachelors (4 Years)' ||
			selectedLevel === 'Masters' ||
			selectedLevel === 'M.Phil' ||
			selectedLevel === 'Ph.D.' ||
			selectedLevel === 'Post Doc'
		) {
			setDegreeOptions([
				{
					label: 'Other Subjects',
					options: Object.entries(Subjects).map(([value, label]) => ({
						value,
						label,
					})),
				},
			]);
		} else {
			setDegreeOptions([]);
		}
	}, [selectedLevel]);

	useEffect(() => {
		const fetchLevelTwoPages = async () => {
			const response = await getPagesByLevel(2);
			if (response.success) {
				setLevelTwoPages(response.data);
			}
		};
		const fetchLevelThreePages = async () => {
			const response = await getPagesByLevel(3);
			if (response.success) {
				setLevelThreePages(response.data);
			}
		};
		const fetchLevelFourPages = async () => {
			const response = await getPagesByLevel(4);
			if (response.success) {
				setLevelFourPages(response.data);
			}
		};

		fetchLevelTwoPages();
		fetchLevelThreePages();
		fetchLevelFourPages();
	}, []);

	return (
		<div className="container mt-5">
			<div className="m-5 card rounded-lg shadow-lg row pt-4">
				<div className="login-header ">
					<h4 className="text-center text-primary ">Add Jobs</h4>
				</div>
				<hr className="mb-2" />
				<div className="card-body">
					<FormikForm
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						title={'Post Form for Data Entry'}
						centerFields={false}
						selectedImages={selectedImages}
						fields={formFields}
					/>
				</div>
			</div>
		</div>
	);
};

export default JobForm;

import React from 'react';
import { Field, ErrorMessage } from 'formik';
import JoditEditor from 'jodit-react';

const Editor = ({ field, form, ...props }) => {
	const handleChange = (value) => {
		form.setFieldValue(field.name, value);
	};

	const editorConfig = {
		readonly: false,
		toolbar: true,
		height: 400,
		direction: 'ltr',
		toolbarButtonSize: 'middle',
		theme: 'default',
		enableDragAndDropFileToEditor: true,

		colorPickerDefaultTab: 'background',
		removeButtons: ['about', 'file'],
		disablePlugins: ['paste', 'stat'],

		uploader: {
			insertImageAsBase64URI: true,
		},
		spellcheck: true,
		buttons: [
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'|',
			'font',
			'fontsize',
			'brush',
			'paragraph',
			'|',
			'align',
			'outdent',
			'indent',
			'lineHeight',
			'ul',
			'ol',

			'|',
			'image',
			'file',
			'video',
			'table',
			'link',
			'|',
			'cut',
			'copy',
			'paste',
			'|',
			'selectall',
			'print',
			'source',
			'|',
			'undo',
			'redo',
			'|',
			'preview',
			'fullsize',
			'|',
			'hr',
			'subscript',
			'superscript',
			'symbols',
			'spellcheck',
		],
		style: {
			font: [
				{ name: 'Arial', value: 'Arial, Helvetica, sans-serif' },
				{ name: 'Georgia', value: 'Georgia, serif' },
				{ name: 'Impact', value: 'Impact, Charcoal, sans-serif' },
				{ name: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
				{
					name: 'Times New Roman',
					value: '"Times New Roman", Times, serif',
				},
				{ name: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
				{
					name: 'Courier New',
					value: '"Courier New", Courier, monospace',
				},
				{
					name: 'Comic Sans MS',
					value: '"Comic Sans MS", cursive, sans-serif',
				},
				{
					name: 'Lucida Sans Unicode',
					value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
				},
				{
					name: 'Palatino Linotype',
					value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
				},
				{
					name: 'Arial Black',
					value: '"Arial Black", Gadget, sans-serif',
				},
				{ name: 'Garamond', value: 'Garamond, serif' },
				{ name: 'Bookman', value: 'Bookman, serif' },
				{
					name: 'Trebuchet MS',
					value: '"Trebuchet MS", Helvetica, sans-serif',
				},
				{ name: 'Arial Narrow', value: '"Arial Narrow", sans-serif' },
				{
					name: 'Century Gothic',
					value: '"Century Gothic", sans-serif',
				},
				{ name: 'Candara', value: 'Candara, sans-serif' },
				{ name: 'Avant Garde', value: '"Avant Garde", sans-serif' },
				{ name: 'Futura', value: 'Futura, sans-serif' },
				{ name: 'Gill Sans', value: '"Gill Sans", sans-serif' },
				{ name: 'Helvetica', value: 'Helvetica, sans-serif' },
				{
					name: 'Lucida Console',
					value: '"Lucida Console", Monaco, monospace',
				},
				{ name: 'Optima', value: 'Optima, sans-serif' },
				{ name: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
				{ name: 'Geneva', value: 'Geneva, sans-serif' },
				{ name: 'Calibri', value: 'Calibri, sans-serif' },
				{
					name: 'Segoe UI',
					value: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
				},
				{ name: 'Roboto', value: 'Roboto, sans-serif' },
				{ name: 'Lato', value: 'Lato, sans-serif' },
				{ name: 'Oswald', value: 'Oswald, sans-serif' },
				{ name: 'Merriweather', value: 'Merriweather, serif' },
				{ name: 'Raleway', value: 'Raleway, sans-serif' },
				{ name: 'Montserrat', value: 'Montserrat, sans-serif' },
				{ name: 'Ubuntu', value: 'Ubuntu, sans-serif' },
				{ name: 'PT Sans', value: '"PT Sans", sans-serif' },
			],
		},
	};

	return (
		<div>
			<JoditEditor
				config={editorConfig}
				value={field.value}
				onBlur={handleChange}
				{...props}
			/>
			<ErrorMessage
				name={field.name}
				component="div"
				className="error-message"
			/>
		</div>
	);
};

const EditorField = (props) => {
	return <Field {...props} component={Editor} />;
};

export default EditorField;

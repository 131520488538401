import React, { useEffect, useState } from 'react';
import DataTableComponent from '../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getJobs } from '../../service/api';

const JobArchives = () => {
	const [jobs, setJobs] = useState([]);

	const columns = [
		{
			name: 'Job Advertisement Number',
			selector: (row) => row.title,
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<Link
						to={`/job/${row.advertisementNumber}`}
						className=" text-nowrap"
					>
						{row.advertisementNumber}
					</Link>
				</div>
			),
			sortable: false,
		},
		{
			name: 'Department',
			selector: (row) => row.department.name,
			sortable: false,
		},
		{
			name: 'Expiry Date',
			selector: (row) =>
				moment(row.lastDateToApply).format('MMMM DD, YYYY'),
			sortable: false,
		},
	];

	useEffect(() => {
		const fetchJobs = async () => {
			const response = await getJobs();
			if (response?.success) {
				const jobsData = response.data;

				setJobs(jobsData);
			}
		};
		fetchJobs();
	}, []);

	return (
		<section className="my-5">
			<div className="bg-light-1 py-5">
				<div className="container">
					{jobs && jobs.length ? (
						<div className="row m-2 ">
							<DataTableComponent columns={columns} data={jobs} />
						</div>
					) : (
						<div className="bg-white text-center mt-5 p-4">
							Loading ...
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default JobArchives;

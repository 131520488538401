import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import axios from 'axios';

import Home from './pages/Home';
import JobPost from './pages/JobPost';
import JobArchives from './pages/JobArchives';
import Login from './pages/Login';
import AboutUs from './pages/About';
import Blog from './pages/Blogs';
import AdminBlogs from './pages/Admin/Blogs';
import Layout from './layout/Layout';
import AdminRoute from './components/AdminRoute';
import AdminLayout from './layout/admin/AdminLayout';
import Admin from './pages/Admin';
import AddAdmin from './pages/Admin/AddAdmin';
import Pages from './pages/Admin/Pages';
import Jobs from './pages/Admin/Jobs';
import AddPage from './pages/Admin/Pages/AddPage';
import AddJob from './pages/Admin/Jobs/AddJob';
import EditAdmin from './pages/Admin/EditAdmin';
import EditPage from './pages/Admin/Pages/EditPage';
import EditJob from './pages/Admin/Jobs/EditJob';
import JobPosts from './pages/JobPosts';
import JobArchivesPost from './pages/JobArchivesPost';
import AddBlog from './pages/Admin/Blogs/AddBlog';
import EditBlog from './pages/Admin/Blogs/EditBlog';
import BlogLayout from './layout/blog/BlogLayout';
import BlogDetails from './pages/Blogs/BlogDetails';
import Subscribers from './pages/Admin/Subscribers';

axios.defaults.withCredentials = true;

function App() {
	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<Home />} />
				<Route path=":levelTwo" element={<JobPosts />}>
					<Route path=":levelThree" element={<JobPosts />}>
						<Route path=":levelFour" element={<JobPosts />} />
					</Route>
				</Route>
				<Route path="/job">
					<Route index element={<JobArchives />} />
					<Route path=":jobAdNumber">
						<Route index element={<JobArchivesPost />} />
						<Route path=":id" element={<JobPost />} />
					</Route>
				</Route>
				<Route path="/About-Us" element={<AboutUs />} />
			</Route>

			<Route path="/Blog" element={<BlogLayout />}>
				<Route index element={<Blog />} />
				<Route path=":id" element={<BlogDetails />} />
			</Route>

			<Route path="/admin" element={<AdminRoute />}>
				<Route element={<AdminLayout />}>
					<Route index element={<Admin />} />

					<Route path="add" element={<AddAdmin />} />
					<Route path="edit/:id" element={<EditAdmin />} />

					<Route path="jobs">
						<Route index element={<Jobs />} />
						<Route path="add" element={<AddJob />} />
						<Route path="edit/:id" element={<EditJob />} />
					</Route>

					<Route path="pages">
						<Route index element={<Pages />} />

						<Route path="add" element={<AddPage />} />
						<Route path="edit/:id" element={<EditPage />} />
					</Route>

					<Route path="blogs">
						<Route index element={<AdminBlogs />} />

						<Route path="add" element={<AddBlog />} />
						<Route path="edit/:id" element={<EditBlog />} />
					</Route>

					<Route path="subscribers">
						<Route index element={<Subscribers />} />
					</Route>
				</Route>
			</Route>
			<Route path="/login" element={<Login />} />
		</Routes>
	);
}

export default App;

import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';

const BlogLayout = () => {
	return (
		<div className="min-vh-100 d-flex flex-column">
			<Header showSearch={false} />
			<main className="flex-grow-1">
				<Outlet />
			</main>
			<Footer showEmailSubs={false} />
		</div>
	);
};

export default BlogLayout;

import { useEffect, useState } from 'react';
import { getBlogs, deleteBlog } from '../../../service/api';
import DataTableComponent from '../../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getUserToken } from '../../../utils/auth';
import moment from 'moment';

const AdminBlogs = () => {
	const [blogs, setBlogs] = useState([]);

	const columns = [
		{
			name: 'Title',
			selector: (row) => row.title,
			sortable: true,
		},
		{
			name: 'Publish Date',
			selector: (row) => moment(row.publishDate).format('MMMM DD, YYYY'),
			sortable: true,
		},
		{
			name: 'Published By',
			selector: (row) => row.publishedBy,
			sortable: true,
		},
		{
			name: 'Image',
			selector: (row) => row.blogImage,
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<Link
						to={`/admin/blogs/edit/${row._id}`}
						className="btn btn-outline-info p-1 m-1 text-nowrap"
					>
						Update
					</Link>
					<button
						className="btn btn-outline-danger p-1 m-1 text-nowrap"
						onClick={() => handleDelete(row._id)}
					>
						Delete
					</button>
				</div>
			),
			sortable: false,
		},
	];

	const handleDelete = async (id) => {
		const confirmed = await Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this blog post!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, cancel!',
			reverseButtons: true,
		});

		if (confirmed.isConfirmed) {
			try {
				const response = await deleteBlog({
					id,
					token: getUserToken(),
				});
				if (response.success) {
					const blogsList = blogs.filter((blog) => blog._id !== id);
					setBlogs(blogsList);
					Swal.fire(
						'Deleted!',
						'The blog post has been deleted.',
						'success',
					);
				}
			} catch (error) {
				console.log('Error deleting blog post:', error);
				Swal.fire(
					'Error!',
					'An error occurred while deleting the blog post.',
					'error',
				);
			}
		} else if (confirmed.dismiss === Swal.DismissReason.cancel) {
			Swal.fire('Cancelled', 'The blog deletion was cancelled.', 'info');
		}
	};

	useEffect(() => {
		const fetchBlogs = async () => {
			const response = await getBlogs();
			if (response.success) {
				setBlogs(response.data);
			}
		};
		fetchBlogs();
	}, []);

	return (
		<>
			<div className="mt-5 mx-md-5 mx-3 card rounded-lg shadow-lg">
				<div className="card-header d-flex justify-content-between h3">
					<span className="d-md-block d-none"></span>
					<span className="text-primary">All Blog Posts</span>
					<Link
						to="/admin/blogs/add"
						className="btn btn-outline-primary"
					>
						Add Blog Post
					</Link>
				</div>
				<div className="card-body">
					<div className="">
						{blogs && blogs.length > 0 ? (
							<DataTableComponent
								columns={columns}
								data={blogs}
								showSearch={true}
								searchKey="title"
							/>
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminBlogs;

import { useEffect, useState } from 'react';
import { getAdmins, deleteAdmin } from '../../service/api';
import DataTableComponent from '../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getUserToken } from '../../utils/auth';

const Admin = () => {
	const [admins, setAdmins] = useState([]);

	const columns = [
		{
			name: 'First Name',
			selector: (row) => row.firstName,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: (row) => row.lastName,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Username',
			selector: (row) => row.username,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => (row.status ? 'Active' : 'Inactive'),
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<Link
						to={`/admin/edit/${row._id}`}
						className="btn btn-outline-info p-1 m-1 text-nowrap"
					>
						Update
					</Link>
					<button
						className="btn btn-outline-danger p-1 m-1 text-nowrap"
						onClick={() => handleDelete(row._id)}
					>
						Delete
					</button>
				</div>
			),
			sortable: false,
		},
	];

	const handleDelete = async (id) => {
		const confirmed = await Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this admin!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, cancel!',
			reverseButtons: true,
		});

		if (confirmed.isConfirmed) {
			try {
				const response = await deleteAdmin({
					id,
					token: getUserToken(),
				});
				if (response.success) {
					const adminsList = admins.filter(
						(admin) => admin._id !== id,
					);
					setAdmins(adminsList);
					Swal.fire(
						'Deleted!',
						'The admin has been deleted.',
						'success',
					);
				}
			} catch (error) {
				console.log('Error deleting admin:', error);
				Swal.fire(
					'Error!',
					'An error occurred while deleting the admin.',
					'error',
				);
			}
		} else if (confirmed.dismiss === Swal.DismissReason.cancel) {
			Swal.fire('Cancelled', 'The admin deletion was cancelled.', 'info');
		}
	};

	useEffect(() => {
		const fetchAdmins = async () => {
			const response = await getAdmins();
			if (response.success) {
				setAdmins(response.data);
			}
		};
		fetchAdmins();
	}, []);

	return (
		<>
			<div className="mt-5 mx-md-5 mx-3 card rounded-lg shadow-lg">
				<div className="card-header d-flex justify-content-between h3">
					<span className="d-md-block d-none"></span>
					<span className="text-primary">All Admins</span>
					<Link to="/admin/add" className="btn btn-outline-primary">
						Add User
					</Link>
				</div>
				<div className="card-body">
					<div className="">
						{admins && admins.length > 0 ? (
							<DataTableComponent
								columns={columns}
								data={admins}
								showSearch={true}
								searchKey={"firstName"}
							/>
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Admin;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { searchJobsByPages } from '../../service/api';
import moment from 'moment';
import DataTableComponent from '../../components/DataTable/DataTableComponent';

const JobPosts = () => {
	const { levelTwo, levelThree, levelFour } = useParams();
	const [jobs, setJobs] = useState([]);

	const columns = [
		{
			name: 'Job Title',
			selector: (row) => row.title,
			sortable: true,
		},
		{
			name: 'Department',
			selector: (row) => row.department.name,
			sortable: true,
		},
		{
			name: 'Grade',
			selector: (row) => row.scale?.replace('_', ' '),
			sortable: true,
		},
		{
			name: 'Last Date To Apply',
			selector: (row) =>
				moment(row.lastDateToApply).format('MMMM DD, YYYY'),
			sortable: true,
		},
		{
			name: 'More Details',
			cell: (row) => {
				const jobTitle = row.title ? row.title.replace(/ /g, '-') : '';
				const department = row.department?.name
					? row.department.name.replace(/ /g, '-')
					: '';
				const openingDate = row.jobOpeningDate
					? moment(row.jobOpeningDate)
							.format('DD-MMM-YYYY')
							.replace(/ /g, '-')
					: '';

				let jobUrl = `/job/${row.advertisementNumber}/${jobTitle}`;
				if (department) {
					jobUrl += `+${department}`;
				}
				if (openingDate) {
					jobUrl += `+${openingDate}`;
				}
				return (
					<div className="d-flex align-items-center justify-content-center">
						<Link
							to={jobUrl}
							className="text-nowrap"
							target="_blank"
						>
							View More Details
						</Link>
					</div>
				);
			},
			sortable: false,
		},
	];

	useEffect(() => {
		const fetchJobs = async () => {
			let slug;
			if (levelTwo) {
				slug = levelTwo;
			}
			if (levelThree) {
				slug = levelThree;
			}
			if (levelFour) {
				slug = levelFour;
			}
			const response = await searchJobsByPages(slug);
			if (response?.success) {
				const jobsData = response.data;

				setJobs(jobsData);
			}
		};
		fetchJobs();
	}, [levelTwo, levelThree, levelFour]);

	return (
		<section className="my-5">
			<div className="bg-light-1 py-5">
				<div className="container">
					{jobs && jobs.length > 0 ? (
						<DataTableComponent columns={columns} data={jobs} />
					) : (
						<div className="bg-white text-center mt-5 p-4">
							Loading ...
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default JobPosts;

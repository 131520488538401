import React from 'react';

const FieldWrapper = ({ fieldSize, children }) => {
	return fieldSize === 'sm' ? (
		<div className="mb-3 col-4">{children}</div>
	) : fieldSize === 'md' ? (
		<div className="mb-3 col-6">{children}</div>
	) : (
		<div className="mb-3 col-12">{children}</div>
	);
};

export default FieldWrapper;

import React from 'react';
import { Field, ErrorMessage } from 'formik';
import FieldWrapper from './FieldWrapper';

const Date = ({ label, name, fieldSize, ...rest }) => {
	return (
		<FieldWrapper fieldSize={fieldSize}>
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<Field
				type="date"
				id={name}
				name={name}
				className="form-control"
				{...rest}
			/>
			<ErrorMessage
				name={name}
				component="div"
				className="error error-message"
			/>
		</FieldWrapper>
	);
};

export default Date;

import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Select from 'react-select';
import FieldWrapper from './FieldWrapper';

const SearchSelect = ({
	label,
	name,
	options,
	values,
	setValues,
	setSelected,
	fieldSize,
	...rest
}) => {
	const handleChange = (selectedOption) => {
		setValues({ ...values, [name]: selectedOption.value });
		if (name == 'province' || name === 'level') {
			setSelected(selectedOption.value);
		}
	};

	return (
		<FieldWrapper fieldSize={fieldSize}>
			<label htmlFor={name} className="form-label">
				{label}:
			</label>
			<Field
				name={name}
				component={Select}
				onChange={handleChange}
				options={[...options]}
				{...rest}
			/>
			<ErrorMessage
				name={name}
				component="div"
				className="error-message"
			/>
		</FieldWrapper>
	);
};

export default SearchSelect;

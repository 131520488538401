import React from 'react';

const AboutUs = () => {
	return (
		<div className="mx-5 px-5">
			Sarkaar.pk is a platform that{"'"}s built with the vision of helping
			the Pakistani youth serve the country by enabling them to join the
			government sector. On Sarkaar.pk, you can easily find government
			jobs from all over the country so you can realize your dream of
			helping the nation.
		</div>
	);
};

export default AboutUs;

import React, { useEffect, useState } from 'react';
import AdminForm from '../../components/AdminForm';
import { useParams } from 'react-router-dom';
import { getAdminById } from '../../service/api';
import { getUserToken } from '../../utils/auth';

const EditAdmin = () => {
	const { id } = useParams();
	const [admin, setAdmin] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchAdmin = async () => {
				try {
					const response = await getAdminById(id, {
						token: getUserToken(),
					});
					if (response.success) {
						setAdmin(response.admin);
					}
				} catch (error) {
					console.log('Error fetching admin:', error);
				}
			};
			fetchAdmin();
		}
	}, []);

	return <>{admin && <AdminForm admin={admin} />}</>;
};

export default EditAdmin;

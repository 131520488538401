import React from 'react';
import AdminForm from '../../components/AdminForm';

const AddAdmin = () => {
	return (
		<>
			<AdminForm />
		</>
	);
};

export default AddAdmin;

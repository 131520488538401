import { FieldArray } from 'formik';
import React from 'react';
import Select from 'react-select';
import Text from './Text';
import Number from './Number';

const CityFields = ({ label, name, values, setFieldValue, options }) => {
	const handleChange = (selectedOptions) => {
		const cities = selectedOptions.map((option) => ({
			id: option.value,
			name: option.label,
			vacancies: '',
		}));

		setFieldValue(name, cities);
	};

	return (
		<FieldArray name={name}>
			{() => (
				<>
					<div className="d-flex mb-3">
						<div className="col-1">
							<span className="text-danger"></span>
							{label}:
						</div>
					</div>
					<div className="d-flex mb-3">
						<div className="col-7">
							<Select
								isMulti
								name="cities"
								options={options}
								onChange={handleChange}
								value={options.filter((option) =>
									values.cities.some(
										(city) => city.id === option.value,
									),
								)}
							/>
						</div>
					</div>
					{values.cities.map((city, index) => (
						<div
							className="row align-items-center justify-content-start mb-1"
							key={index}
						>
							<Text
								label={'City'}
								name={`${name}[${index}].name`}
								fieldSize="sm"
								readOnly
							/>

							<Number
								label={'Vacancies'}
								name={`${name}[${index}].vacancies`}
								fieldSize="sm"
							/>
						</div>
					))}
				</>
			)}
		</FieldArray>
	);
};

export default CityFields;

import { apiRequest } from '../fetch';

export const addBlog = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'POST',
			url: '/blog',
			data: payload,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getBlogs = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/blog`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getBlogById = async (id) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/blog/${id}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getBlogByURL = async (url) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/blog/url/${url}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const deleteBlog = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'DELETE',
			url: '/blog/' + payload.id,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const updateBlog = async (id, payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'PUT',
			url: `/blog/${id}`,
			data: payload,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

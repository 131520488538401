import React from 'react';
import { Field, ErrorMessage } from 'formik';
import FieldWrapper from './FieldWrapper';

const Text = ({ label, name, fieldSize, ...rest }) => {
	return (
		<FieldWrapper fieldSize={fieldSize}>
			<label htmlFor={name} className="form-label">
				{label}:
			</label>
			<Field
				type="text"
				id={name}
				name={name}
				{...rest}
				className="form-control "
			/>
			<ErrorMessage
				name={name}
				component="div"
				className="error-message"
			/>
		</FieldWrapper>
	);
};

export default Text;

export const getPageURL = (page) => {
	return page.level === 2
		? `/${page.slug}`
		: page.level === 3
			? `/${page?.levelTwoParent.slug}/${page.slug}`
			: page.level === 4
				? `/${page?.levelTwoParent?.slug}/${page.levelThreeParent ? page?.levelThreeParent.slug + '/' : ''}${page.slug}`
				: '/';
};

export const getQueryParams = (query) => {
	const queryParams = new URLSearchParams(query);
	const params = {};
	for (const [key, value] of queryParams.entries()) {
		params[key] = value;
	}
	return params;
};

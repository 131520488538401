import React from 'react';
import Text from './Text';
import Number from './Number';
import MultiSearchSelect from './MultiSearchSelect';
import SearchSelect from './SearchSelect';

const GroupFields = ({ label, children, values, setValues }) => {
	return (
		<>
			<div className="d-flex mb-3">
				<div className="col-12">
					<span className="text-danger "></span>
					{label}:
				</div>
			</div>
			{children[0].map((childField, childIndex) => (
				<div
					className="row align-items-center justify-content-center mb-1"
					key={childIndex}
				>
					{childField.type === 'text' && (
						<Text type="text" {...childField} />
					)}
					{childField.type === 'number' && (
						<Number type="number" {...childField} />
					)}
					{childField.type === 'select' && (
						<SearchSelect
							{...childField}
							values={values}
							setValues={setValues}
						/>
					)}
					{childField.type === 'multiselect' && (
						<MultiSearchSelect
							{...childField}
							values={values}
							setValues={setValues}
						/>
					)}
				</div>
			))}
		</>
	);
};

export default GroupFields;

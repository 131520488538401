import React, { useState } from 'react';
import { subscribeEmail } from '../service/api';

const EmailSubscription = () => {
	const [email, setEmail] = useState('');
	const [subscribed, setSubscribed] = useState(false);

	const handleInputChange = (event) => {
		setEmail(event.target.value);
	};

	const isEmailValid = () => {
		if (email) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(email);
		}
		return false;
	};
	const handleSubmit = async () => {
		if (isEmailValid()) {
			try {
				const response = await subscribeEmail({ email });
				if (response.success) {
					console.log(response.data);
				}
			} catch (error) {
				console.log('Email addition failed.', error);
			}

			setSubscribed(true);
		}
	};

	return (
		<div className="container my-5 ">
			<div className="m-2 row justify-content-center  p-3">
				<div className=" row justify-content-between align-items-center shadow rounded-5 px-5 py-4">
					{!subscribed ? (
						<>
							<div className="col-xl-5 col-lg-6 col-md-12 col-12 my-4 fw-bold ps-0">
								<span className=" fs-4 ">
									Never Want to Miss Any{' '}
									<strong className="text-primary">
										Job News?
									</strong>{' '}
								</span>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-12 col-12 shadow-sm row rounded-5 justify-content-center align-items-center ">
								<div className="col-xl-8 col-lg-8 col-md-8 col-12">
									<input
										type="text"
										className="form-control border-0 p-0 font-2 "
										placeholder="Enter Your Email"
										value={email}
										onChange={handleInputChange}
									/>
								</div>

								<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-10 justify-content-center mt-2  ">
									<button
										type="button"
										className="btn btn-primary  btn-lg fw-bold form-control bg-primary text-white search-button p-2 my-2 justify-content-end "
										onClick={handleSubmit}
									>
										Submit
									</button>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="col-xl-12 col-lg-12 col-md-12 col-12 my-4 fw-bold text-center ps-0">
								<span className=" fs-4 ">
									Email{' '}
									<strong className="text-primary">
										Subscribed
									</strong>{' '}
									Successfully!
								</span>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmailSubscription;

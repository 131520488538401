export const PayScales = {
	BPS: 'BPS',
	PPS: 'PPS',
	MP: 'MP',
	G: 'G',
	SPPS: 'Special Professional Pay Scale',
	OTHERS: 'Others',
};

export const BPS = {
	BPS_1: 'BPS 1',
	BPS_2: 'BPS 2',
	BPS_3: 'BPS 3',
	BPS_4: 'BPS 4',
	BPS_5: 'BPS 5',
	BPS_6: 'BPS 6',
	BPS_7: 'BPS 7',
	BPS_8: 'BPS 8',
	BPS_9: 'BPS 9',
	BPS_10: 'BPS 10',
	BPS_11: 'BPS 11',
	BPS_12: 'BPS 12',
	BPS_13: 'BPS 13',
	BPS_14: 'BPS 14',
	BPS_15: 'BPS 15',
	BPS_16: 'BPS 16',
	BPS_17: 'BPS 17',
	BPS_18: 'BPS 18',
	BPS_19: 'BPS 19',
	BPS_20: 'BPS 20',
	BPS_21: 'BPS 21',
	BPS_22: 'BPS 22',
};

export const PPS = {
	PPS_1: 'PPS 1',
	PPS_2: 'PPS 2',
	PPS_3: 'PPS 3',
	PPS_4: 'PPS 4',
	PPS_5: 'PPS 5',
	PPS_6: 'PPS 6',
	PPS_7: 'PPS 7',
	PPS_8: 'PPS 8',
	PPS_9: 'PPS 9',
	PPS_10: 'PPS 10',
	PPS_11: 'PPS 11',
};

export const MP = {
	MP_1: 'MP 1',
	MP_2: 'MP 2',
	MP_3: 'MP 3',
};

export const G = {
	G_1: 'G 1',
	G_2: 'G 2',
	G_3: 'G 3',
	G_4: 'G 4',
	G_5: 'G 5',
};

export const SPPS = {
	SPPS_I: 'SPPS I',
	SPPS_II: 'SPPS II',
	SPPS_III: 'SPPS III',
};

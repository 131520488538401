import React from 'react';
import JobForm from '../../../components/JobForm';

const AddJob = () => {
	return (
		<>
			<JobForm />
		</>
	);
};

export default AddJob;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogByURL } from '../../service/api';
import './BlogDetails.css';
import moment from 'moment';

const BlogDetails = () => {
	const { id } = useParams();
	const [post, setPost] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchPost = async () => {
				try {
					const response = await getBlogByURL(id);
					if (response?.success) {
						setPost(response.data);
					}
				} catch (error) {
					console.log('Error fetching post:', error);
				}
			};
			fetchPost();
		}
	}, [id]);

	return (
		<>
			{post && (
				<div className="card border-0 text-center mx-5 mb-5 rounded-5">
					<img
						src={process.env.REACT_APP_BACKEND_URL + post.blogImage}
						className="card-img-top blog-image rounded-top-5"
						alt={post.title}
					/>
					<div className="blog-details-card card-body px-5">
						<h2 className="card-title">{post.title}</h2>
						<p className="card-text">
							<small className="text-muted">
								Last updated{' '}
								{moment(post.publishDate).fromNow()}
							</small>
						</p>
						<div
							className="card-text"
							dangerouslySetInnerHTML={{
								__html: post.blogDescription,
							}}
						></div>
					</div>
				</div>
			)}
		</>
	);
};

export default BlogDetails;

import { setToken } from "../../utils/auth";
import { apiRequest } from "../fetch";

export const loginUser = async (payload) => {
    try {
        const response = await apiRequest({
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            url: '/user/login',
            data: JSON.stringify({ ...payload }),
        });

        if (response && response.data) {
                setToken(response.data.token)

            return response.data;
        }
    } catch (error) {
        console.log(error.message);
        throw error;
    }
};

export const getLoggedIn = async () => {
    const response = await apiRequest({
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: '/user/loggedIn',
    });
    return response.data;
};

export const getAdmins = async () => {
    try {
        const response = await apiRequest({
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
            url: `/user`,
        });
        if (response && response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.message);
        throw error;
    }
};

export const addAdmin = async (payload) => {
    try {
        const response = await apiRequest({
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            url: '/user',
            data: JSON.stringify({ ...payload }),
        });
        if (response && response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.message);
        throw error;
    }
};

export const getAdminById = async (id, payload) => {
    try {
        const response = await apiRequest({
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            url: `/user/${id}`,
        });
        if (response && response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.message);
        throw error;
    }
};

export const updateAdmin = async (id, payload) => {
    try {
        const response = await apiRequest({
            headers: {
                Authorization: `Bearer ${payload.token}`,
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            url: `/user/${id}`,
            data: JSON.stringify(payload),
        });
        if (response && response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.message);
        throw error;
    }
};

export const deleteAdmin = async (payload) => {
    try {
        const response = await apiRequest({
            headers: {
                Authorization: `Bearer ${payload.token}`,
            },
            method: 'DELETE',
            url: `/user/${payload.id}`,
        });
        if (response && response.data) {
            return response.data;
        }
    } catch (error) {
        console.log(error.message);
        throw error;
    }
};

export const setToken = (token) => {
	localStorage.setItem('token', token);
}

export const isAuthenticated = () => {
	const token = localStorage.getItem('token');
	return checkJWT(token);
};

export const getUserToken = () => {
	const token = localStorage.getItem('token');
	return token;
};


export const checkJWT = (token) => {
	if (!token) {
		return false;
	}

	const parts = token.split('.');
	if (parts.length !== 3) {
		return false;
	}

	try {
		const payload = JSON.parse(atob(parts[1]));
		if (payload.exp < Date.now() / 1000) {
			return false;
		}
	} catch (error) {
		return false;
	}

	return true;
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import FormikForm from '../../components/FormikForm/FormikForm';
import { loginUser } from '../../service/api';
import { setToken } from '../../utils/auth';

const Login = () => {
	const navigate = useNavigate();
	const initialValues = {
		username: '',
		password: '',
	};

	const formFields = [
		{ type: 'text', label: 'Username', name: 'username' },
		{
			type: 'password',
			label: 'Password',
			name: 'password',
			autoComplete: 0,
		},
	];

	const validationSchema = Yup.object({
		username: Yup.string().required('* Username is required'),
		password: Yup.string().required('* Password is required'),
	});

	const onSubmit = async (values) => {
		try {
			const response = await loginUser(values);
			if (response.success) {
				setToken(response.token);
				navigate('/admin');
			}
		} catch (error) {
			console.log('Login failed. Please check your credentials.', error);
		}
	};

	return (
		<div className="container " style={{ minWidth: '100px' }}>
			<div
				className="container d-flex justify-content-center align-items-center"
				style={{ minHeight: '100vh' }}
			>
				<div
					className="col-md-7 bg-white col-12 rounded-5 shadow-lg py-4"
					style={{ minWidth: '300px' }}
				>
					<div className="row justify-content-center align-items-start m-2">
						<div className="">
							<div className="login-header mb-2">
								<h3 className="text-center text-primary">
									Login
								</h3>
							</div>
							<hr className="mb-2" />

							<FormikForm
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
								title={'Login'}
								centerFields={true}
								fields={formFields}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;

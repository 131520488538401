import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getJobByTitle } from '../../service/api';
import moment from 'moment';
import SharePost from '../../components/SharePost';
import SearchBar from '../../components/SearchBar';
import DisqusComments from '../../components/DisqusComments';
import ImageCarousel from '../../components/ImageCarousel';
import JobSchema from '../../components/SchemaMarkups/JobSchema';
import './JobPost.css';

const JobPost = () => {
	const { id } = useParams();
	const [job, setJob] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (id) {
			const fetchJob = async () => {
				try {
					const spitedTitle = id.split('+');
					const data = {};

					if (spitedTitle[0]) {
						data.title = spitedTitle[0].replace(/-/g, ' ');
					}

					if (spitedTitle[1]) {
						data.department = spitedTitle[1].replace(/-/g, ' ');
					}

					if (spitedTitle[2]) {
						data.jobOpeningDate = moment.utc(spitedTitle[2]);
					}

					const response = await getJobByTitle(data);
					if (response?.success) {
						setJob(response.data);
					}
				} catch (error) {
					console.log('Error fetching job:', error);
				}
			};
			fetchJob();
		} else {
			navigate('/');
		}
	}, []);

	const quotaMapping = {
		speciallyAble: 'Specially Ables',
		transgender: 'Transgender',
		retireesChildren: 'Retirees Children',
		women: 'Women',
		minority: 'Minority',
	};

	return (
		<>
			{job && (
				<>
					<section className="section-box ">
						<div className="container">
							<div className="row card job-details-card mx-1 my-5 py-3">
								<div className="align-items-center col-12">
									<h1 className="jobCard-title text-md-center mb-md-3">
										{job.title}
									</h1>

									<div className="row  ">
										{job.department &&
											job.department.name && (
												<div className="col-md-6 d-flex my-2">
													<div className="job-responsive-1 text-start fw-light">
														Department
													</div>
													<div className="job-responsive-2">
														<Link
															to={`/${job.department.slug}`}
															className="text-decoration-none"
														>
															<strong className="small-heading">
																{
																	job
																		.department
																		.name
																}
															</strong>
														</Link>
													</div>
												</div>
											)}
										{job?.scale && (
											<div className="col-md-6 d-flex my-2 ">
												<div className="job-responsive-1 fw-light">
													Scale
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.scale}
													</strong>
												</div>
											</div>
										)}
									</div>

									<div className="row ">
										{job?.degree &&
											job.degree.length > 0 &&
											job.division && (
												<div className="col-md-6 d-flex my-2 ">
													<div className="job-responsive-1 fw-light">
														Education
													</div>
													<div className="job-responsive-2">
														<strong className="small-heading">
															{job.degree.join(
																', ',
															)}{' '}
															({job.division})
														</strong>
													</div>
												</div>
											)}
										{job.requiredExperience && (
											<div className="col-md-6 d-flex my-2 ">
												<div className="job-responsive-1 text-start fw-light">
													Experience
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.requiredExperience}{' '}
														years
													</strong>
												</div>
											</div>
										)}
									</div>

									<div className="row ">
										{job.gender && (
											<div className="col-md-6 d-flex my-2 ">
												<div className="job-responsive-1 fw-light">
													Gender
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.gender} Only
													</strong>
												</div>
											</div>
										)}

										{job.minimumAge && (
											<div className="col-md-6 d-flex my-2 ">
												<div className="job-responsive-1 text-start fw-light">
													Age Range
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.minimumAge}
														{job.maximumAge
															? ` - ${job.maximumAge}`
															: ''}
													</strong>
												</div>
											</div>
										)}
									</div>

									<div className="row ">
										{job.totalVacancies > 0 && (
											<div className="col-md-6 d-flex my-2">
												<div className="job-responsive-1 fw-light">
													No. of Vacancies
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.totalVacancies}
													</strong>
												</div>
											</div>
										)}
									</div>

									<div className="row mx-1">
										{job.cities &&
											job.cities.length > 0 &&
											job.cities.map(
												(city, index) =>
													city?.vacancies > 0 && (
														<div
															className="row p-0"
															key={index}
														>
															<div className="col-md-6 d-flex my-2">
																<div className="job-responsive-1">
																	<strong className="small-heading">
																		{
																			city.name
																		}
																	</strong>
																</div>
																<div className="job-responsive-2 ">
																	<strong className="small-heading">
																		{
																			city.vacancies
																		}{' '}
																		{city.vacancies >
																		1
																			? 'Vacancies'
																			: 'Vacancy'}
																	</strong>
																</div>
															</div>
														</div>
													),
											)}
									</div>
									<div className="row">
										{job?.jobOpeningDate && (
											<div className="col-md-6 d-flex my-2">
												<div className="job-responsive-1 fw-light">
													Job Opening Date
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.jobOpeningDate &&
															moment(
																job.jobOpeningDate,
															).format(
																'MMMM DD, YYYY',
															)}
													</strong>
												</div>
											</div>
										)}
										{job?.lastDateToApply && (
											<div className="col-md-6 d-flex my-2">
												<div className="job-responsive-1 fw-light">
													Last Date to Apply
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.lastDateToApply &&
															moment(
																job.lastDateToApply,
															).format(
																'MMMM DD, YYYY',
															)}
													</strong>
												</div>
											</div>
										)}
									</div>
									<div className="row">
										{job?.approximateSalary > 0 && (
											<div className="col-md-6 d-flex my-2">
												<div className="job-responsive-1 fw-light">
													Salary
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job?.approximateSalary}
													</strong>
												</div>
											</div>
										)}

										{job.employmentType && (
											<div className="col-md-6 d-flex my-2 ">
												<div className="job-responsive-1 fw-light">
													Employment type
												</div>
												<div className="job-responsive-2">
													<strong className="small-heading">
														{job.employmentType}
													</strong>
												</div>
											</div>
										)}
									</div>

									<div className="row ">
										{job.hiringAgency &&
											job.hiringAgency.name && (
												<div className="col-md-6 d-flex my-2">
													<div className="job-responsive-1 fw-light">
														Hiring Agency
													</div>
													<div className="job-responsive-2">
														<Link
															to={`/Service-Commission-Jobs/${job.hiringAgency.slug}`}
															className="text-decoration-none"
														>
															<strong className="small-heading">
																{
																	job
																		.hiringAgency
																		.name
																}
															</strong>
														</Link>
													</div>
												</div>
											)}

										{job.newspaper &&
											job.newspaper.name && (
												<div className="col-md-6 d-flex my-2">
													<div className="job-responsive-1 fw-light">
														Newspaper
													</div>
													<div className="job-responsive-2">
														<Link
															to={`/Jobs-by-Newspaper/${job.newspaper.slug}`}
															className="text-decoration-none"
														>
															<strong className="small-heading">
																{
																	job
																		.newspaper
																		.name
																}
															</strong>
														</Link>
													</div>
												</div>
											)}
									</div>

									<div className="row ">
										{job.quotaBased &&
											Object.values(job.quotaBased).some(
												(quota) => {
													return quota > 0;
												},
											) && (
												<div className="row m-1 p-0">
													<div className="col-md-6 d-flex my-2 ">
														<div className="job-responsive-1 fw-light">
															Quota
														</div>

														<div className="job-responsive-2">
															Vacancies
														</div>
													</div>

													{Object.entries(
														job.quotaBased,
													).map(
														([
															quotaKey,
															quotaValue,
														]) =>
															quotaValue > 0 && (
																<div
																	className="row "
																	key={
																		quotaKey
																	}
																>
																	<div className="col-md-6 d-flex my-2">
																		<strong className="job-responsive-1">
																			{quotaMapping[
																				quotaKey
																			] ||
																				quotaKey}
																		</strong>

																		<strong className="job-responsive-2">
																			{
																				quotaValue
																			}
																		</strong>
																	</div>
																</div>
															),
													)}
												</div>
											)}
									</div>

									<div className="row ">
										{job.advertisementNumber && (
											<div className="col-md-6 d-flex my-2">
												<div className="job-responsive-1 fw-light">
													Job Ad Number
												</div>
												<div className="job-responsive-2">
													<Link
														to={`/job/${job.advertisementNumber}`}
														className="text-decoration-none"
													>
														<strong className="small-heading">
															{
																job.advertisementNumber
															}
														</strong>
													</Link>
												</div>
											</div>
										)}
									</div>
									<div className="row  ">
										{job.howToApply && (
											<>
												<div className="col-md-12 my-2">
													<div className="job-responsive-1 fw-light">
														How to Apply
													</div>
												</div>
												<div className="col-md-12   ">
													<strong className="text-wrap">
														{job.howToApply}
													</strong>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
						{job.advertisementImage && (
							<ImageCarousel images={job.advertisementImage} />
						)}
						<SharePost postUrl={document.location.href} />
						<DisqusComments post={job} />
					</section>
					<div className="container">
						<SearchBar />
					</div>
					<JobSchema job={job} />
				</>
			)}
		</>
	);
};

export default JobPost;

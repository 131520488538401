import React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import FormikForm from './FormikForm/FormikForm';
import { addAdmin, updateAdmin } from '../service/api';

const AdminForm = ({ admin }) => {
	const navigate = useNavigate();

	const initialValues = {
		firstName: '',
		lastName: '',
		username: '',
		email: '',
		phoneNumber: '',
		password: '',
		status: '',
	};

	if (admin) {
		initialValues.firstName = admin.firstName;
		initialValues.lastName = admin.lastName;
		initialValues.username = admin.username;
		initialValues.email = admin.email;
		initialValues.phoneNumber = admin.phoneNumber;
		initialValues.status = admin.status ? 'active' : 'inactive';
	}

	const formFields = [
		{
			type: 'text',
			label: 'First Name',
			name: 'firstName',
			fieldSize: 'md',
		},
		{ type: 'text', label: 'Last Name', name: 'lastName', fieldSize: 'md' },
		{
			type: 'text',
			label: 'Username',
			name: 'username',
			fieldSize: 'md',
			readOnly: admin ? true : false,
		},
		{
			type: 'email',
			label: 'Email',
			name: 'email',
			fieldSize: 'md',
			readOnly: admin ? true : false,
		},
		{
			type: 'password',
			label: 'Password',
			name: 'password',
			fieldSize: 'md',
		},
		{
			type: 'text',
			label: 'Phone Number',
			name: 'phoneNumber',
			placeholder: '923001234567',
			fieldSize: 'md',
		},
		{
			type: 'select',
			label: 'Status',
			name: 'status',
			options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Inactive',
					value: 'inactive',
				},
			],
			fieldSize: 'md',
		},
	];

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		username: Yup.string().required('Username is required'),
		email: Yup.string()
			.email('Invalid email')
			.required('Email is required'),
		phoneNumber: Yup.string()
			.matches(/^[0-9]{12}$/, 'Invalid phone number')
			.required('Phone Number is required'),
		password: Yup.string(),
		status: Yup.string().required('Status is required'),
	});

	const onSubmit = async (values) => {
		try {
			if (admin) {
				// If ID exists, it's for updating an existing admin
				values.status = values.status == 'active' ? true : false;
				await updateAdmin(admin._id, values);
			} else {
				// If ID doesn't exist, it's for adding a new admin
				await addAdmin(values);
			}
			navigate('/admin');
		} catch (error) {
			console.log('Admin creation/update failed.', error);
		}
	};

	return (
		<div className="container mt-5">
			<div className="m-5 card rounded-lg shadow-lg row pt-4">
				<div className="login-header ">
					<h4 className="text-center text-primary ">
						{admin ? 'Edit Admin' : 'Add Admin'}
					</h4>
				</div>
				<hr className="mb-2" />
				<div className="card-body">
					<FormikForm
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						centerFields={false}
						fields={formFields}
					/>
				</div>
			</div>
		</div>
	);
};

export default AdminForm;

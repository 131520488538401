import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import {
	getArmedForcesPages,
	getDepartmentPages,
	getHiringAgencyPages,
	getNewspaperPages,
	getPagesBySlug,
	getProfessionPages,
	getProvinceCitiesPages,
} from '../service/api';
import { getPageURL } from '../utils/page';
import SearchLayout from './SearchLayout';

const Header = ({ showSearch = true }) => {
	const [armedForces, setArmedForces] = useState([]);
	const [professions, setProfessions] = useState([]);
	const [hiringAgencies, setHiringAgencies] = useState([]);
	const [newspapers, setNewspapers] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [cities, setCities] = useState([]);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [isOpen, setIsOpen] = useState(
		window.innerWidth >= 992 ? true : false,
	);
	const location = useLocation();

	const levelTwoPages = [
		{ label: 'Federal Jobs', path: 'Federal-Jobs' },
		{ label: 'Punjab Jobs', path: 'Government-Jobs-in-Punjab' },
		{ label: 'Sindh Jobs', path: 'Sindh-Government-Jobs' },
		{ label: 'KPK Jobs', path: 'Government-Jobs-in-KPK' },
		{ label: 'Balochistan Jobs', path: 'Balochistan-Government-Jobs' },
		{ label: 'Islamabad Jobs', path: 'Islamabad-Jobs' },
		{ label: 'GB Jobs', path: 'Government-Jobs-in-Gilgit-Baltistan' },
		{ label: 'AJK Jobs', path: 'Government-Jobs-in-Azad-Kashmir' },
	];

	const menuItems = [
		{
			label: 'Armed Forces Jobs',
			id: 'armedForces',
			children: armedForces.map((armedForce) => ({
				label: armedForce.name,
				link: getPageURL(armedForce),
			})),
		},
		{
			label: 'Service Commission Jobs',
			id: 'serviceCommission',
			children: hiringAgencies.map((agency) => ({
				label: agency.name,
				link: getPageURL(agency),
			})),
		},
		{
			label: 'Jobs by Profession',
			id: 'jobsByProfession',
			children: professions.map((profession) => ({
				label: profession.name,
				link: getPageURL(profession),
			})),
		},
		{
			label: 'Job By Newspaper',
			id: 'jobByNewspaper',
			children: newspapers.map((newspaper) => ({
				label: newspaper.name,
				link: getPageURL(newspaper),
			})),
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [
					armedForcesResponse,
					professionsResponse,
					newspapersResponse,
					hiringAgenciesResponse,
				] = await Promise.all([
					getArmedForcesPages(),
					getProfessionPages(),
					getNewspaperPages(),
					getHiringAgencyPages(),
				]);

				if (armedForcesResponse.success) {
					setArmedForces(armedForcesResponse.data);
				}

				if (professionsResponse.success) {
					setProfessions(professionsResponse.data);
				}

				if (newspapersResponse.success) {
					setNewspapers(newspapersResponse.data);
				}

				if (hiringAgenciesResponse.success) {
					setHiringAgencies(hiringAgenciesResponse.data);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const getProvinceDepartments = async () => {
			const path = location.pathname;
			const currentProvince = levelTwoPages.find((page) =>
				path.includes(page.path),
			);

			if (currentProvince) {
				const response = await getPagesBySlug(currentProvince.path);
				if (response && response.data) {
					setSelectedProvince(response.data);
					const fetchAdditionalData = async () => {
						try {
							const departmentsResponse =
								await getDepartmentPages(response.data._id);

							if (departmentsResponse.success) {
								setDepartments(departmentsResponse.data);
							}
							const citiesResponse = await getProvinceCitiesPages(
								response.data._id,
							);

							if (citiesResponse.success) {
								setCities(citiesResponse.data);
							}
						} catch (error) {
							console.error(
								'Error fetching additional data:',
								error,
							);
						}
					};

					fetchAdditionalData();
				}
			} else {
				setSelectedProvince(null);
				setDepartments([]);
				setCities([]);
			}
		};

		getProvinceDepartments();
	}, [location.pathname]);

	return (
		<header className="mb-5">
			<nav className="navbar navbar-expand-lg navbar-light bg-light-1 sticky-top">
				<div className="container">
					<Link
						to="/"
						className="navbar-brand align-self-start m-2 fw-bolder text-primary"
					>
						Sarkaar
					</Link>

					<button
						className={`navbar-toggler custom-toggler ${isOpen ? 'open' : ''}`}
						type="button"
						onClick={() => setIsOpen(!isOpen)}
						data-bs-toggle="collapse"
						data-bs-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="toggler-icon"></span>
					</button>

					<div
						className={`navbar-collapse ${isOpen || window.innerWidth >= 992 ? 'show' : 'collapse'}`}
						id="navbarNav"
					>
						<div className="d-flex flex-lg-wrap justify-content-evenly col-xxl-10">
							<ul className="navbar-nav m-0 justify-content-center align-items-center">
								{levelTwoPages.map((item, index) => (
									<li
										className="nav-item m-1 navbar-size nav-pills"
										key={index}
									>
										<NavLink
											to={`/${item.path}`}
											className="nav-link px-2"
											activeclassname="active"
										>
											{item.label}
										</NavLink>
									</li>
								))}
							</ul>
							<ul className="navbar-nav ml-auto justify-content-center align-items-center">
								{selectedProvince && (
									<li className="nav-item dropdown m-1 navbar-size">
										<Link
											className="nav-link dropdown-toggle"
											href="#"
											role="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Jobs by {selectedProvince.name}{' '}
											Department
										</Link>
										<ul className="dropdown-menu border-0 shadow-lg text-center font-2">
											{departments &&
											departments.length ? (
												departments.map(
													(department, index) => (
														<li key={index}>
															<Link
																className="dropdown-item mb-2"
																to={getPageURL(
																	department,
																)}
															>
																{
																	department.name
																}
															</Link>
														</li>
													),
												)
											) : (
												<li>
													<span className="dropdown-item mb-2">
														No departments found
													</span>
												</li>
											)}
										</ul>
									</li>
								)}
								{selectedProvince &&
									selectedProvince.slug !== 'Federal-Jobs' &&
									selectedProvince.slug !==
										'Islamabad-Jobs' && (
										<li className="nav-item dropdown m-1 navbar-size">
											<Link
												className="nav-link dropdown-toggle"
												href="#"
												id="provinceCities"
												role="button"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												Jobs by {selectedProvince.name}{' '}
												Cities
											</Link>
											<ul
												className="dropdown-menu border-0 shadow-lg text-center font-2"
												aria-labelledby="provinceCities"
											>
												{cities.map((city, index) => (
													<li key={index}>
														<Link
															className="dropdown-item mb-2"
															to={getPageURL(
																city,
															)}
														>
															{city.name}
														</Link>
													</li>
												))}
											</ul>
										</li>
									)}
								{menuItems.map((menuItem) => (
									<li
										key={menuItem.id}
										className="nav-item dropdown m-1 navbar-size"
									>
										{menuItem.children &&
										menuItem.children.length > 0 ? (
											<>
												<Link
													className="nav-link dropdown-toggle"
													href="#"
													id={menuItem.id}
													role="button"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													{menuItem.label}
												</Link>
												<ul
													className="dropdown-menu border-0 shadow-lg text-center font-2"
													aria-labelledby={
														menuItem.id
													}
												>
													{menuItem.children.map(
														(child, index) => (
															<li key={index}>
																<a
																	className="dropdown-item mb-2"
																	href={
																		child.link
																	}
																>
																	{
																		child.label
																	}
																</a>
															</li>
														),
													)}
												</ul>
											</>
										) : (
											<Link
												className="nav-link"
												to={menuItem.link}
											>
												{menuItem.label}
											</Link>
										)}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</nav>
			{showSearch && (
				<SearchLayout>
					<SearchBar />
				</SearchLayout>
			)}
		</header>
	);
};

export default Header;

import { apiRequest } from '../fetch.js';

export const getJobs = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/job`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		// throw error;
	}
};

export const addJob = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'POST',
			url: '/job',
			data: JSON.stringify({ ...payload }),
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getJobById = async (id) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/job/${id}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getJobByTitle = async (data) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
			url: `/job/title`,
			data,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getJobsByAdNumber = async (id) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/job/ad-number/${id}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const searchJobs = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/job/search`,
			params: payload,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const searchJobsByPages = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/job/page/${payload}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		// throw error;
	}
};

import React from 'react';
import EmailSubscription from '../components/EmailSubscription';
import { Link } from 'react-router-dom';

const Footer = ({ showEmailSubs = true }) => {
	const pages = [
		{ label: 'Federal Jobs', path: 'Federal-Jobs' },
		{ label: 'Punjab Jobs', path: 'Government-Jobs-in-Punjab' },
		{ label: 'Sindh Jobs', path: 'Sindh-Government-Jobs' },
		{ label: 'KPK Jobs', path: 'Government-Jobs-in-KPK' },
		{ label: 'Balochistan Jobs', path: 'Balochistan-Government-Jobs' },
		{ label: 'Islamabad Jobs', path: 'Islamabad-Jobs' },
		{ label: 'GB Jobs', path: 'Government-Jobs-in-Gilgit-Baltistan' },
		{ label: 'AJK Jobs', path: 'Government-Jobs-in-Azad-Kashmir' },
	];

	return (
		<>
			{showEmailSubs && <EmailSubscription />}
			<footer className="pt-5 pb-3 px-5 bg-light-1 mt-auto mb-0">
				<div className="container">
					<div className="row ">
						<div className="col-xl-4 col-lg-6 col-sm-6 mb-4">
							<h3>Sarkaar</h3>
							<div className="footer-font">
								Sarkaar.pk is a platform that{"'"}s built with
								the vision of helping the Pakistani youth serve
								the country by enabling them to join the
								government sector. On Sarkaar.pk, you can easily
								find government jobs from all over the country
								so you can realize your dream of helping the
								nation.
							</div>
						</div>
						<div className="col-xl-4 col-lg-6 col-sm-4 mb-4">
							<h3>Site Map</h3>
							<ul className="list-unstyled footer-font">
								{pages.map((item, index) => (
									<li key={index} className="mb-2">
										<Link
											to={`/${item.path}`}
											className="text-decoration-none text-dark mb-3"
										>
											{item.label}
										</Link>
									</li>
								))}
							</ul>
						</div>

						<div className="col-xl-4 col-lg-6 col-sm-4">
							<h3>Who Are We</h3>
							<ul className="list-unstyled footer-font">
								<li className="mb-2">
									<Link
										to={`/about-us`}
										className="text-decoration-none text-dark mb-3"
									>
										About Us
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={`/job`}
										className="text-decoration-none text-dark mb-3"
									>
										Job Archives
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={`/Blog`}
										className="text-decoration-none text-dark mb-3"
									>
										Blogs
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={`/Jobs-by-Profession`}
										className="text-decoration-none text-dark mb-3"
									>
										Jobs by Profession
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={`/Jobs-by-Newspaper`}
										className="text-decoration-none text-dark mb-3"
									>
										Jobs by Newspaper
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={`/Service-Commission-Jobs`}
										className="text-decoration-none text-dark mb-3"
									>
										Service Commission Jobs
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={`/Armed-Forces-Jobs`}
										className="text-decoration-none text-dark mb-3"
									>
										Armed Forces Jobs
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<hr />
					<div className="copyright">
						<div className="container">
							<div className="site-info">
								© 2024 — All Rights Reserved
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;

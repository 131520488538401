import { apiRequest } from '../fetch';

export const subscribeEmail = async (data) => {
	try {
		const response = await apiRequest({
			method: 'POST',
			url: '/email/subscribe',
			data,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getAllSubscribers = async () => {
	try {
		const response = await apiRequest({
			method: 'GET',
			url: '/email/subscribers',
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

import React, { useEffect, useState } from 'react';
import BlogForm from '../../../components/BlogForm';
import { useParams } from 'react-router-dom';
import { getBlogById } from '../../../service/api';
import { getUserToken } from '../../../utils/auth';

const EditBlog = () => {
	const { id } = useParams();
	const [blog, setBlog] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchBlog = async () => {
				try {
					const response = await getBlogById(id, {
						token: getUserToken(),
					});
					if (response.success) {
						setBlog(response.data);
					}
				} catch (error) {
					console.log('Error fetching blog:', error);
				}
			};
			fetchBlog();
		}
	}, []);

	return <>{blog && <BlogForm blog={blog} />}</>;
};

export default EditBlog;

import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';

const JobSchema = ({ job }) => {
	// Initialize an empty object for jobSchemaData
	const jobSchemaData = {
		'@context': 'https://schema.org',
		'@type': 'JobPosting',
		title: job.title,
		datePosted: moment(job.jobOpeningDate).format('MMM DD, YYYY HH:mm:ss'),
		validThrough: moment(job.lastDateToApply).format(
			'MMM DD, YYYY HH:mm:ss',
		),
		employmentType: job.employmentType,
	};

	// Add hiringOrganization if it exists
	if (job.hiringAgency && job.hiringAgency.name) {
		jobSchemaData.hiringOrganization = {
			'@type': 'Organization',
			name: job.hiringAgency.name,
		};
		if (job.hiringAgency.url) {
			jobSchemaData.hiringOrganization.sameAs = job.hiringAgency.url;
		}
		if (job.hiringAgency.logo) {
			jobSchemaData.hiringOrganization.logo = job.hiringAgency.logo;
		}
	}

	// Add jobLocation if cities and province exist
	if (
		job.cities &&
		job.cities.length > 0 &&
		job.province &&
		job.province.name
	) {
		jobSchemaData.jobLocation = {
			'@type': 'Place',
			address: {
				'@type': 'PostalAddress',
				addressLocality: job.cities.map((city) => city.name).join(', '),
				addressRegion: job.province.name,
				addressCountry: 'PK',
			},
		};
	}

	// Add relevantOccupation
	if (job.profession && job.profession.name) {
		jobSchemaData.relevantOccupation = job.profession.name;
	}

	// Add baseSalary if approximateSalary exists
	if (job.approximateSalary) {
		jobSchemaData.baseSalary = {
			'@type': 'MonetaryAmount',
			currency: 'PKR',
			value: {
				'@type': 'QuantitativeValue',
				value: job.approximateSalary,
				unitText: 'MONTH',
			},
		};
	}

	// Add description if howToApply exists
	if (job.howToApply) {
		jobSchemaData.description = job.howToApply;
	}

	// Add skills if profession exists
	if (job.profession && job.profession.name) {
		jobSchemaData.skills = job.profession.name;
	}

	// Add qualifications if degree exists
	if (job.degree && job.degree.length > 0) {
		jobSchemaData.qualifications = job.degree;
	}

	// Add responsibilities if division exists
	if (job.division) {
		jobSchemaData.educationRequirements = job.division;
	}

	// Add experienceRequirements if requiredExperience exists
	if (job.requiredExperience) {
		jobSchemaData.experienceRequirements = {
			'@type': 'OccupationalExperienceRequirements',
			monthsOfExperience: job.requiredExperience * 12,
		};
	}

	// Optional fields handling
	if (job.quotaBased) {
		jobSchemaData.jobBenefits = [];
		if (job.quotaBased.speciallyAble) {
			jobSchemaData.jobBenefits.push('Specially-abled candidates');
		}
		if (job.quotaBased.transgender) {
			jobSchemaData.jobBenefits.push('Transgender candidates');
		}
		if (job.quotaBased.retireesChildren) {
			jobSchemaData.jobBenefits.push("Retirees' children");
		}
		if (job.quotaBased.women) {
			jobSchemaData.jobBenefits.push('Women candidates');
		}
		if (job.quotaBased.minority) {
			jobSchemaData.jobBenefits.push('Minority candidates');
		}
	}

	// Add totalJobOpenings if totalVacancies exists
	if (job.totalVacancies) {
		jobSchemaData.totalJobOpenings = job.totalVacancies;
	}

	// Filter out undefined/null fields from jobSchemaData
	const filteredJobSchemaData = Object.fromEntries(
		Object.entries(jobSchemaData).filter(
			([_, value]) => value !== undefined && value !== null,
		),
	);

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(filteredJobSchemaData)}
			</script>
		</Helmet>
	);
};

export default JobSchema;

export const EducationLevels = {
	PRIMARY: 'Primary',
	MIDDLE: 'Middle',
	MATRIC: 'Matric',
	INTERMEDIATE: 'Intermediate',
	DAE: 'DAE',
	BACHELORS_2_YEARS: 'Bachelors (2 Years)',
	BACHELORS_4_YEARS: 'Bachelors (4 Years)',
	MASTERS: 'Masters',
	M_PHIL: 'M.Phil',
	PHD: 'Ph.D.',
	POST_DOC: 'Post Doc',
};

export const MATRIC = {
	SCIENCE: 'Science',
	ARTS: 'Arts',
};

export const INTERMEDIATE = {
	FSC: 'F.Sc.',
	FA: 'FA',
	ICS: 'ICS',
	ICOM: 'I.Com',
};

export const BACHELORS_2_YEARS = {
	BA: 'BA',
	BCOM: 'B.Com',
	BCS: 'BCS',
	BSC: 'B.Sc',
};

export const BACHELORS_4_YEARS = {
	BA: 'BA',
	BCOM: 'B.Com',
	BCS: 'BCS',
	BSC: 'B.Sc',
};

export const DAE = {
	ELECTRICAL: 'Electrical',
	CIVIL: 'Civil',
	MECHANICAL: 'Mechanical',
	CHEMICAL: 'Chemical',
	ELECTRONICS: 'Electronics',
};

export const Subjects = {
	AGRICULTURE: 'Agriculture',
	PSYCHOLOGY: 'Psychology',
	ARABIC: 'Arabic',
	ARCHAEOLOGY: 'Archaeology',
	ARCHITECTURE: 'Architecture',
	ART_HISTORY: 'Art History',
	BIOCHEMISTRY: 'Biochemistry',
	BIOSTATISTICS: 'Biostatistics',
	BOTANY: 'Botany',
	BUSINESS_ADMINISTRATION: 'Business Administration',
	BUSINESS_ECONOMICS: 'Business Economics',
	CHEMICAL_ENGINEERING: 'Chemical Engineering',
	CHEMISTRY: 'Chemistry',
	COMPUTER_SCIENCE: 'Computer Science',
	CRIMINOLOGY: 'Criminology',
	DEVELOPMENT_SCIENCES: 'Development Sciences',
	ECONOMICS: 'Economics',
	EDUCATION: 'Education',
	ENGLISH: 'English',
	ENVIRONMENTAL_SCIENCES: 'Environmental Sciences',
	FINE_ARTS: 'Fine Arts',
	FRENCH: 'French',
	GENDER_STUDIES: 'Gender Studies',
	GEOGRAPHY: 'Geography',
	HEALTH_ADMINISTRATION: 'Health Administration',
	HISTORY: 'History',
	HUMAN_RESOURCE: 'Human Resource',
	INFORMATION_TECHNOLOGY: 'Information Technology',
	INTERNATIONAL_RELATIONS: 'International Relations',
	ISLAMIAT: 'Islamiat',
	LAW: 'Law',
	METALLURGY: 'Metallurgy',
	MICROBIOLOGY: 'Microbiology',
	PAKISTAN_STUDIES: 'Pakistan Studies',
	PERSIAN: 'Persian',
	PHILOSOPHY: 'Philosophy',
	PHYSICAL_EDUCATION: 'Physical Education',
	PHYSICS: 'Physics',
	POLITICAL_SCIENCE: 'Political Science',
	PUBLIC_ADMINISTRATION: 'Public Administration',
	SOCIOLOGY: 'Sociology',
	SPACE_SCIENCE: 'Space Science',
	SPECIAL_EDUCATION: 'Special Education',
	STATISTICS: 'Statistics',
	TEXTILE_DESIGN: 'Textile Design',
	TOURISM: 'Tourism',
	URDU: 'Urdu',
	ZOOLOGY: 'Zoology',
};

export const Divisions = {
	FIRST_DIVISION: '1st Division',
	SECOND_DIVISION: '2nd Division',
	THIRD_DIVISION: '3rd Division',
};

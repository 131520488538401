import React from 'react';

const ImageCarousel = ({ images }) => {
	return (
		<section className="section-box">
			<div className="container">
				<div className="row mx-5 px-5 my-5 py-3">
					<div
						id="carouselExampleControls"
						className="carousel slide"
						data-bs-ride="carousel"
					>
						<div className="carousel-inner">
							{images.map((image, index) => (
								<div
									key={index}
									className={`carousel-item ${index === 0 ? 'active' : ''}`}
								>
									<img
										className="d-block w-100"
										src={`${process.env.REACT_APP_BACKEND_URL}${image}`}
										alt={`Slide ${index + 1}`}
										style={{
											maxHeight: '500px',
											objectFit: 'cover',
										}}
									/>
								</div>
							))}
						</div>
						<a
							className="carousel-control-prev"
							href="#carouselExampleControls"
							role="button"
							data-bs-slide="prev"
						>
							<span
								className="carousel-control-prev-icon"
								aria-hidden="true"
							></span>
							<span className="sr-only">Previous</span>
						</a>
						<a
							className="carousel-control-next"
							href="#carouselExampleControls"
							role="button"
							data-bs-slide="next"
						>
							<span
								className="carousel-control-next-icon"
								aria-hidden="true"
							></span>
							<span className="sr-only">Next</span>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ImageCarousel;

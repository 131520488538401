import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DataTableComponent from '../../components/DataTable/DataTableComponent';
import { Link, useLocation } from 'react-router-dom';
import { getJobs, searchJobs } from '../../service/api';
import { getQueryParams } from '../../utils/page';
import './HomeDetails.css';
const Home = () => {
	const [jobs, setJobs] = useState([]);

	const columns = [
		{
			name: 'Job Title',
			selector: (row) => row.title,
			sortable: true,
		},
		{
			name: 'Department',
			selector: (row) => row.department.name,
			sortable: true,
		},
		{
			name: 'Grade',
			selector: (row) => row.scale?.replace('_', ' '),
			sortable: true,
		},
		{
			name: 'Last Date To Apply',
			selector: (row) =>
				moment(row.lastDateToApply).format('MMMM DD, YYYY'),
			sortable: true,
		},
		{
			name: 'More Details',
			cell: (row) => {
				const jobTitle = row.title ? row.title.replace(/ /g, '-') : '';
				const department = row.department?.name
					? row.department.name.replace(/ /g, '-')
					: '';
				const openingDate = row.jobOpeningDate
					? moment(row.jobOpeningDate)
							.format('DD-MMM-YYYY')
							.replace(/ /g, '-')
					: '';

				let jobUrl = `/job/${row.advertisementNumber}/${jobTitle}`;
				if (department) {
					jobUrl += `+${department}`;
				}
				if (openingDate) {
					jobUrl += `+${openingDate}`;
				}
				return (
					<div className="d-flex align-items-center justify-content-center ">
						<Link
							to={jobUrl}
							className="data-table-cell"
							target="_blank"
						>
							View More Details
						</Link>
					</div>
				);
			},

			sortable: false,
		},
	];

	const location = useLocation();
	const params = getQueryParams(location.search);

	useEffect(() => {
		const fetchJobs = async () => {
			let response;
			if (params) {
				response = await searchJobs(params);
				localStorage.removeItem('searchFilter');
			} else {
				response = await getJobs();
			}
			if (response?.success) {
				const jobsData = response.data;
				const departments = {};
				jobsData.forEach((job) => {
					const departmentName = job.department.name;
					if (!departments[departmentName]) {
						departments[departmentName] = [];
					}
					departments[departmentName].push(job);
				});
				for (const departmentJobs of Object.values(departments)) {
					departmentJobs.sort((a, b) =>
						b.scale?.localeCompare(a.scale),
					);
				}
				setJobs(departments);
			}
		};
		fetchJobs();
	}, [location]);

	return (
		<>
			<section className="my-5">
				<div className="bg-light-1 py-5">
					<div className="container">
						<div className="row m-2 ">
							{Object?.entries(jobs).length ? (
								Object?.entries(jobs).map(
									([department, departmentJobs]) => (
										<div key={department}>
											{
												<DataTableComponent
													columns={columns}
													data={departmentJobs}
												/>
											}
										</div>
									),
								)
							) : (
								<div className="bg-white text-center mt-5 p-4">
									Loading ...
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home;

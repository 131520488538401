import React, { useEffect, useState } from 'react';
import { getAllSubscribers } from '../../../service/api';
import DataTableComponent from '../../../components/DataTable/DataTableComponent';
import moment from 'moment';

const Subscribers = () => {
	const [subscribers, setSubscribers] = useState([]);

	useEffect(() => {
		const fetchAllSubscribers = async () => {
			const response = await getAllSubscribers();
			if (response.success) {
				setSubscribers(response.data);
			}
		};
		fetchAllSubscribers();
	}, []);

	const columns = [
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => (row.status ? 'Subscribed' : 'Unsubscribed'),
			sortable: true,
		},
		{
			name: 'Subscribed On',
			selector: (row) =>
				moment(row.createdAt).format('MMMM DD, YYYY hh:mm a'),
			sortable: true,
		},
	];

	return (
		<>
			<div className="mt-5 mx-md-5 mx-3 card rounded-lg shadow-lg">
				<div className="card-header d-flex justify-content-center h3">
					<span className="text-primary">All Subscribers</span>
				</div>
				<div className="card-body">
					<div className="">
						{subscribers && subscribers.length > 0 ? (
							<DataTableComponent
								columns={columns}
								data={subscribers}
								showSearch={true}
								searchKey="email"
							/>
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Subscribers;

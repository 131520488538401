import React from 'react';
import AdminSidebar from './AdminSidebar';
import AdminNav from './AdminNav';
import { Outlet } from 'react-router-dom';
import Main from './Main';

const AdminLayout = () => {
	return (
		<>
			<div>
				<div className="col-12">
					<AdminNav />
				</div>

				<div className="d-flex h-100 bg-light-1">
					<div className="col-2 mb-3">
						<AdminSidebar />
					</div>
					<div className="col-10 mb-3">
						<Main>
							<Outlet />
						</Main>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminLayout;

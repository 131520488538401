export const EmploymentType = {
	PART_TIME: 'Part Time',
	FULL_TIME: 'Full Time',
	DAILY_WAGES: 'Daily Wages',
	PERMANENT: 'Permanent',
	REGULAR: 'Regular',
	CONTRACT: 'Contract',
	CONTINGENCY: 'Contingency',
	ADHOC_BASIS: 'Adhoc Basis',
};

import React, { useState } from 'react';
import logoImage from '../../assets/images/profileLogo.png';
import { Link } from 'react-router-dom';

const AdminNav = () => {
	const [showProfileDropdown, setShowProfileDropdown] = useState(false);
	return (
		<>
			<nav className="navbar navbar-expand-lg shadow border-bottom position-static">
				<div className="container-fluid ps-4 pe-3 ">
					<div className="row w-100 justify-content-between">
						<div className="col-auto  d-md-block d-none">
							<span className="h1 text-primary  ">Sarkaar</span>
						</div>

						<div className="col-auto d-block d-md-none">
							<div className=" navbar-expand-md ">
								<button
									className="navbar-toggler"
									type="button"
								>
									<span className="navbar-toggler-icon"></span>
								</button>
							</div>
						</div>

						<div className="col-auto d-md-block d-none p-2">
							<span className="h3 light-primary ">
								Admin Dashboard
							</span>
						</div>

						<div className="col-auto d-block d-md-none">
							<div className="h1 text-primary">Sarkaar</div>
						</div>

						<div className="col-auto p-0">
							<div className="btn-group dropstart">
								<button
									type="button"
									className="btn border-0 p-0"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<Link className="navbar-brand m-0 p-0">
										<img
											src={logoImage}
											alt="Logo"
											className="logo-size btn p-0 "
											onClick={() =>
												setShowProfileDropdown(
													!showProfileDropdown,
												)
											}
										/>
									</Link>
								</button>
								<div className="dropdown-menu">
									<ul className="dropdown-content d-block navbar-nav p-1">
										<li className="navbar-item">
											<Link
												className="dropdown-item link-hover rounded navbar-item"
												to="/profile"
											>
												<i className="fa-solid fa-user me-2 "></i>{' '}
												Profile
											</Link>
										</li>
										<li className="navbar-item">
											<Link
												className="dropdown-item link-hover rounded navbar-link"
												to="/login"
											>
												<i className="fa-solid fa-arrow-right-from-bracket me-2 "></i>{' '}
												Log Out
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default AdminNav;

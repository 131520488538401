import React from 'react';
import BlogForm from '../../../components/BlogForm';

const AddBlog = () => {
	return (
		<>
			<BlogForm />
		</>
	);
};

export default AddBlog;

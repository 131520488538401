import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getDepartmentPages, getProvinces } from '../service/api';
import { BPS, G, MP, PPS, SPPS } from '../utils/enum/scales.enum';
import { EducationLevels } from '../utils/enum/education.enum';
import { EmploymentType } from '../utils/enum/employmentType.enum';
import { Gender } from '../utils/enum/gender.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams } from '../utils/page';

const SearchBar = () => {
	const [provinces, setProvinces] = useState([]);
	const [provinceMapping, setProvinceMapping] = useState({});
	const [departments, setDepartments] = useState([]);
	const [selectedProvinceSlug, setSelectedProvinceSlug] = useState(null);
	const [advancedSearchToggle, setAdvancedSearchToggle] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const {
		province,
		department,
		city,
		scale,
		keyword,
		level,
		experience,
		quota,
		employmentType,
		gender,
		age,
		advancedSearch,
	} = getQueryParams(location.search);

	const initialSearchFilter = {
		province: province || null,
		department: department || null,
		city: city || '',
		scale: scale || null,
		keyword: keyword || '',
		level: level || null,
		experience: experience || null,
		quota: quota || null,
		employmentType: employmentType || null,
		gender: gender || null,
		age: age || '',
	};

	const [searchFilter, setSearchFilter] = useState({});

	const handleChange = (selectedOption, field) => {
		if (field === 'province') {
			setSelectedProvinceSlug(
				selectedOption ? selectedOption.value : null,
			);
			setSearchFilter((prevState) => ({
				...prevState,
				department: null,
			}));
			setDepartments([]);
		}
		setSearchFilter((prevState) => ({
			...prevState,
			[field]: selectedOption ? selectedOption.value : null,
		}));
	};

	const handleInputChange = (event, field) => {
		const { value } = event.target;
		setSearchFilter((prevState) => ({
			...prevState,
			[field]: value,
		}));
	};

	const handleAdvancedInputChange = (selectedOption, field) => {
		setSearchFilter((prevState) => ({
			...prevState,
			[field]: selectedOption ? selectedOption.value : null,
		}));
	};

	const handleSearch = async () => {
		const searchParams = new URLSearchParams();

		for (const key in searchFilter) {
			if (searchFilter[key]) {
				searchParams.append(key, searchFilter[key]);
			}
		}

		if (advancedSearchToggle) {
			searchParams.append('advancedSearch', true);
		}

		const newUrl = `/?${searchParams.toString()}`;
		navigate(newUrl, { replace: true });
	};

	useEffect(() => {
		const fetchDepartments = async () => {
			if (selectedProvinceSlug) {
				const provinceId = provinceMapping[selectedProvinceSlug];
				const departmentsResponse =
					await getDepartmentPages(provinceId);
				if (departmentsResponse.success) {
					setDepartments(departmentsResponse.data);
				}
			} else {
				setDepartments([]); // Clear departments if no province is selected
			}
		};
		fetchDepartments();
	}, [selectedProvinceSlug, provinceMapping]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getProvinces();
				if (response.success) {
					setProvinces(response.data);
					const mapping = response.data.reduce((acc, province) => {
						acc[province.slug] = province._id;
						return acc;
					}, {});
					setProvinceMapping(mapping);

					// Automatically set selected province and fetch its departments if present in query params
					if (province) {
						setSelectedProvinceSlug(province);
					}
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (!province) {
			setSearchFilter(initialSearchFilter);
			setDepartments([]);
		} else {
			setSearchFilter((prevState) => ({
				...prevState,
				province: province,
				department: department || null,
				city: city || '',
				scale: scale || null,
				keyword: keyword || '',
				level: level || null,
				experience: experience || null,
				quota: quota || null,
				employmentType: employmentType || null,
				gender: gender || null,
				age: age || '',
			}));
		}

		if (advancedSearch) {
			setAdvancedSearchToggle(true);
		} else {
			setAdvancedSearchToggle(false);
		}
	}, [location, province]);

	return (
		<section className="container my-4">
			<div className="shadow-sm card rounded-5 mb-2">
				<div className="row mx-0 p-3 align-items-center">
					<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
						<Select
							name="province"
							placeholder="Province"
							className="form-control border-0 font-2 m-0 p-0"
							options={provinces.map((province) => ({
								label: province.name,
								value: province.slug,
							}))}
							value={
								searchFilter.province
									? {
											label: provinces.find(
												(prov) =>
													prov.slug ===
													searchFilter.province,
											)?.name,
											value: searchFilter.province,
										}
									: null
							}
							onChange={(selectedOption) =>
								handleChange(selectedOption, 'province')
							}
							isClearable
						/>
					</div>

					<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
						<Select
							name="department"
							placeholder="Department"
							className="form-control border-0 font-2 m-0 p-0"
							options={departments.map((department) => ({
								label: department.name,
								value: department.slug,
							}))}
							value={
								searchFilter.department
									? {
											label: departments.find(
												(dept) =>
													dept.slug ===
													searchFilter.department,
											)?.name,
											value: searchFilter.department,
										}
									: null
							}
							onChange={(selectedOption) =>
								handleChange(selectedOption, 'department')
							}
							isClearable
						/>
					</div>

					<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
						<input
							type="text"
							className="form-control border-0 border-bottom font-2"
							placeholder="City"
							value={searchFilter.city}
							onChange={(event) =>
								handleInputChange(event, 'city')
							}
						/>
					</div>

					<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
						<Select
							name="scale"
							placeholder="Grade"
							className="form-control border-0 font-2 m-0 p-0"
							options={[
								{
									label: 'BPS',
									options: Object.keys(BPS).map((key) => ({
										value: BPS[key],
										label: BPS[key],
									})),
								},
								{
									label: 'PPS',
									options: Object.keys(PPS).map((key) => ({
										value: PPS[key],
										label: PPS[key],
									})),
								},
								{
									label: 'MP',
									options: Object.keys(MP).map((key) => ({
										value: MP[key],
										label: MP[key],
									})),
								},
								{
									label: 'G',
									options: Object.keys(G).map((key) => ({
										value: G[key],
										label: G[key],
									})),
								},
								{
									label: 'SPPS',
									options: Object.keys(SPPS).map((key) => ({
										value: SPPS[key],
										label: SPPS[key],
									})),
								},
								{
									label: 'OTHERS',
									options: [
										{
											label: 'Others',
											value: 'others',
										},
									],
								},
							]}
							value={
								searchFilter.scale
									? {
											value: searchFilter.scale,
											label:
												Object.entries({
													...BPS,
													...PPS,
													...MP,
													...G,
													...SPPS,
												}).find(
													(scale) =>
														scale[1] ===
														searchFilter.scale,
												)[1] || null,
										}
									: null
							}
							onChange={(selectedOption) =>
								handleAdvancedInputChange(
									selectedOption,
									'scale',
								)
							}
							isClearable
						/>
					</div>

					<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
						<input
							type="text"
							className="form-control border-0 border-bottom font-2"
							placeholder="Search..."
							value={searchFilter.keyword}
							onChange={(event) =>
								handleInputChange(event, 'keyword')
							}
						/>
					</div>

					{!advancedSearchToggle && (
						<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
							<button
								className="btn-search btn btn-primary rounded-pill font-2 w-100"
								onClick={handleSearch}
							>
								Search Jobs
							</button>
						</div>
					)}

					<div className="col-12 text-end mb-2">
						<button
							className=" btn btn-link font-2"
							onClick={() =>
								setAdvancedSearchToggle(!advancedSearchToggle)
							}
						>
							Advanced Search
						</button>
					</div>
				</div>

				{advancedSearchToggle && (
					<>
						<div className="row mx-0 p-3 align-items-center">
							<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
								<Select
									name="level"
									placeholder="Education Level"
									className="form-control border-0 font-2 m-0 p-0"
									options={Object.keys(EducationLevels).map(
										(key) => ({
											value: EducationLevels[key],
											label: EducationLevels[key],
										}),
									)}
									value={
										searchFilter.level
											? {
													label: Object.entries(
														EducationLevels,
													).find(
														(educationLevel) =>
															educationLevel[1] ===
															searchFilter.level,
													)?.[1],
													value: searchFilter.level,
												}
											: null
									}
									onChange={(selectedOption) =>
										handleAdvancedInputChange(
											selectedOption,
											'level',
										)
									}
									isClearable
								/>
							</div>

							<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
								<Select
									name="experience"
									placeholder="Minimum Experience"
									className="form-control border-0 font-2 m-0 p-0"
									options={Array.from(
										{ length: 20 },
										(_, index) => ({
											value: index + 1,
											label: index + 1 + ' years',
										}),
									)}
									value={
										searchFilter.experience
											? {
													label: `${searchFilter.experience} years`,
													value: searchFilter.experience,
												}
											: null
									}
									onChange={(selectedOption) =>
										handleChange(
											selectedOption,
											'experience',
										)
									}
									isClearable={true}
								/>
							</div>

							<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
								<Select
									name="quota"
									placeholder="Quota"
									className="form-control border-0 font-2 m-0 p-0"
									options={[
										{
											label: 'Specially Able',
											value: 'speciallyAble',
										},
										{
											label: 'Transgender',
											value: 'transgender',
										},
										{
											label: 'Retirees Children',
											value: 'retireesChildren',
										},
										{
											label: 'Women',
											value: 'women',
										},
										{
											label: 'Minority',
											value: 'minority',
										},
									]}
									value={
										searchFilter.quota
											? {
													label: [
														{
															label: 'Specially Able',
															value: 'speciallyAble',
														},
														{
															label: 'Transgender',
															value: 'transgender',
														},
														{
															label: 'Retirees Children',
															value: 'retireesChildren',
														},
														{
															label: 'Women',
															value: 'women',
														},
														{
															label: 'Minority',
															value: 'minority',
														},
													].find(
														(option) =>
															option.value ===
															searchFilter.quota,
													)?.label,
													value: searchFilter.quota,
												}
											: null
									}
									onChange={(selectedOption) =>
										handleAdvancedInputChange(
											selectedOption,
											'quota',
										)
									}
									isClearable
								/>
							</div>

							<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
								<Select
									name="employmentType"
									placeholder="Employment Type"
									className="form-control border-0 font-2 m-0 p-0"
									options={Object.keys(EmploymentType).map(
										(key) => ({
											value: EmploymentType[key],
											label: EmploymentType[key],
										}),
									)}
									value={
										searchFilter.employmentType
											? {
													label: Object.entries(
														EmploymentType,
													).find(
														(employmentType) =>
															employmentType[1] ===
															searchFilter.employmentType,
													)?.[1],
													value: searchFilter.employmentType,
												}
											: null
									}
									onChange={(selectedOption) =>
										handleAdvancedInputChange(
											selectedOption,
											'employmentType',
										)
									}
									isClearable
								/>
							</div>

							<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
								<Select
									name="gender"
									placeholder="Gender"
									className="form-control border-0 font-2 m-0 p-0"
									options={Object.keys(Gender).map((key) => ({
										value: Gender[key],
										label: Gender[key],
									}))}
									value={
										searchFilter.gender
											? {
													label: Object.entries(
														Gender,
													).find(
														(gender) =>
															gender[1] ===
															searchFilter.gender,
													)?.[1],
													value: searchFilter.gender,
												}
											: null
									}
									onChange={(selectedOption) =>
										handleAdvancedInputChange(
											selectedOption,
											'gender',
										)
									}
									isClearable
								/>
							</div>

							<div className="col-xl-2 col-lg-2 col-md-6 col-12 pt-3 mb-3">
								<input
									type="number"
									className="form-control border-0 border-bottom font-2"
									placeholder="Age"
									value={searchFilter.age}
									onChange={(event) =>
										handleInputChange(event, 'age')
									}
								/>
							</div>
						</div>
						<div className=" text-end pt-3 mb-3">
							<button
								className="btn-search btn btn-primary rounded-pill font-2 mx-5 px-5"
								onClick={handleSearch}
							>
								Search Jobs
							</button>
						</div>
						{advancedSearchToggle && (
							<div className="col-xl-2 col-lg-2 col-md-6 col-12 "></div>
						)}
					</>
				)}
			</div>
		</section>
	);
};

export default SearchBar;

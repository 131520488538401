import axios from 'axios';

const backendUrl = process.env.REACT_APP_API_URL;

export const apiRequest = async ({ headers, method, url, data, params }) => {
	const config = {
		headers: headers,
		method: method,
		url: backendUrl + url,
		params: params,
		data: data,
		withCredentials: true,
	};

	const response = await axios.request(config);
	return response;
};

import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import Number from './Number';
import Image from './Image';
import Text from './Text';
import TextArea from './TextArea';
import Date from './Date';
import SearchSelect from './SearchSelect';
import MultiSearchSelect from './MultiSearchSelect';
import GroupFields from './GroupFields';
import CityFields from './CityFields';
import EditorField from './TextEditor';

const FormikForm = ({
	initialValues,
	validationSchema,
	onSubmit,
	fields,
	selectedImages,
	children,
}) => {
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ values, setValues, setFieldValue }) => (
				<Form>
					<div className="row d-flex justify-content-center align-items-center">
						{children}
						{fields.map((field, index) => (
							<React.Fragment key={index}>
								{field.type === 'select' && (
									<SearchSelect
										{...field}
										values={values}
										setValues={setValues}
									/>
								)}
								{field.type === 'multiselect' && (
									<MultiSearchSelect
										{...field}
										values={values}
										setValues={setValues}
									/>
								)}

								{field.type === 'number' && (
									<Number {...field} fieldSize={'md'} />
								)}
								{field.type === 'file' && <Image {...field} />}
								{field.type === 'file' &&
								selectedImages.length ? (
									<div className="mb-3 d-flex align-items-center justify-content-center col-12">
										{field.type === 'file' &&
											selectedImages.map(
												(selectedImage, index) =>
													selectedImage && (
														<img
															className="mx-2"
															key={index}
															src={selectedImage}
															alt={`Preview ${index + 1}`}
															width={100}
															height={100}
														/>
													),
											)}
									</div>
								) : null}
								{field.type === 'text' && <Text {...field} />}
								{field.type === 'email' && <Text {...field} />}
								{field.type === 'password' && (
									<Text {...field} />
								)}
								{field.type === 'textarea' && (
									<TextArea {...field} />
								)}
								{field.type === 'date' && <Date {...field} />}

								{field.type === 'group' && (
									<GroupFields
										{...field}
										values={values}
										setValues={setValues}
									/>
								)}

								{field.type === 'cities' && (
									<CityFields
										{...field}
										values={values}
										setValues={setValues}
										setFieldValue={setFieldValue}
									/>
								)}

								{field.type === 'editor' && (
									<EditorField
										{...field}
										values={values}
										setValues={setValues}
										setFieldValue={setFieldValue}
									/>
								)}
							</React.Fragment>
						))}
					</div>
					<div className="pt-4 d-flex justify-content-center">
						<Button
							text={'Submit'}
							type={'submit'}
							classes={'btn-outline-primary btn-lg'}
							// isDisabled={isSubmitting}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};

FormikForm.propTypes = {
	initialValues: PropTypes.object.isRequired,
	validationSchema: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default FormikForm;

import React from 'react';
import { Helmet } from 'react-helmet';

const OrganizationSchema = () => {
	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify({
					'@context': 'https://schema.org',
					'@type': 'Organization',
					name: 'Sarkaar',
					url: 'https://www.sarkaar.pk',
					contactPoint: {
						'@type': 'ContactPoint',
						telephone: '+92-22-4873016',
						contactType: 'Customer Service',
						areaServed: 'PK',
						availableLanguage: ['Urdu', 'English'],
					},
					address: {
						'@type': 'PostalAddress',
						streetAddress: 'Kalma Chowk',
						addressLocality: 'Lahore',
						addressRegion: 'Punjab',
						postalCode: '54000',
						addressCountry: 'PK',
					},
				})}
			</script>
		</Helmet>
	);
};

export default OrganizationSchema;

import React, { useEffect, useState } from 'react';
import { getBlogs } from '../../service/api';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Blog = () => {
	const [blogs, setBlogs] = useState([]);

	useEffect(() => {
		const fetchBlogs = async () => {
			try {
				const response = await getBlogs();
				if (response && response.data) {
					setBlogs(response.data);
				}
			} catch (error) {
				console.error('Error fetching blogs:', error);
			}
		};

		fetchBlogs();
	}, []);

	return (
		<>
			<section className="py-3 pb-md-5 py-xl-8">
				<div className="container overflow-hidden">
					<div className="row gy-4 gy-lg-0">
						{blogs.map((blog) => (
							<div
								key={blog._id}
								className="col-12 my-4 col-lg-6"
							>
								<Link
									to={`/Blog/${blog.blogURL}`}
									className="link-dark text-decoration-none"
								>
									<article>
										<div className="card border-0 mx-5">
											<figure className="card-img-top m-0 overflow-hidden bsb-overlay-hover">
												<img
													className="card-img-top bsb-scale bsb-hover-scale-up"
													loading="lazy"
													src={
														process.env
															.REACT_APP_BACKEND_URL +
														blog.blogImage
													}
													alt={blog.title}
													style={{
														objectFit: 'cover',
														maxHeight: '300px',
													}}
												/>
											</figure>
											<div className="card-body border bg-white p-4">
												<div className="entry-header mb-3">
													<h2 className="card-title entry-title h4 mb-0">
														{blog.title}
													</h2>
												</div>
												<button className="btn btn-primary m-0 text-nowrap entry-more">
													Read More
												</button>
											</div>
											<div className="card-footer border border-top-0 bg-light p-4">
												<ul className="entry-meta list-unstyled d-flex align-items-center m-0">
													<li>
														<Link
															className="fs-7 link-secondary text-decoration-none d-flex align-items-center"
															to={`/Blog/${blog.blogURL}`}
														>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="14"
																height="14"
																fill="currentColor"
																className="bi bi-calendar3"
																viewBox="0 0 16 16"
															>
																<path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
																<path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
															</svg>
															<span className="ms-2 fs-7">
																{moment(
																	blog.publishDate,
																).format(
																	'MMMM DD, YYYY',
																)}
															</span>
														</Link>
													</li>
												</ul>
											</div>
										</div>
									</article>
								</Link>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default Blog;

import React, { useState } from 'react';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	TwitterIcon,
	FacebookIcon,
	LinkedinIcon,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';

const SharePost = ({ postUrl }) => {
	const [copied, setCopied] = useState(false);

	const handleCopyClick = () => {
		navigator.clipboard.writeText(postUrl);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	};

	return (
		<div className="d-flex justify-content-center">
			<div className="d-flex bg-light rounded align-items-center p-3">
				{/* Copy Button with Tooltip */}
				<button
					className="rounded-circle border-0 bg-transparent"
					onClick={handleCopyClick}
					data-tooltip-id="my-tooltip"
					data-tooltip-content={
						copied ? 'Copied!' : 'Copy to clipboard'
					}
					data-bs-content={copied && 'Copied!'}
				>
					<FontAwesomeIcon
						icon={faCopy}
						size="xl"
						className="rounded-circle p-2"
						border
					/>
				</button>

				<Tooltip id="my-tooltip" effect="solid" place="top" />

				{/* Social Media Share Buttons */}
				<WhatsappShareButton url={postUrl} className="mx-3">
					<WhatsappIcon size={40} round={true} />
				</WhatsappShareButton>

				<LinkedinShareButton url={postUrl} className="mx-3">
					<LinkedinIcon size={40} round={true} />
				</LinkedinShareButton>

				<FacebookShareButton url={postUrl} className="mx-3">
					<FacebookIcon size={40} round={true} />
				</FacebookShareButton>

				<TwitterShareButton url={postUrl} className="mx-3">
					<TwitterIcon size={40} round={true} />
				</TwitterShareButton>
			</div>
		</div>
	);
};

export default SharePost;

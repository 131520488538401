import React from 'react';

const Button = ({ text, type, classes, isDisabled, handleClick}) => {
	return (
		<button
			className={'btn px-3 py-2 fw-bolder rounded-pill ' + classes}
			type={type}
			disabled={isDisabled}
            onClick={handleClick}
		>
			{text}
		</button>
	);
};

export default Button;

import React from 'react';

const SearchLayout = ({ children }) => {
	return (
		<div className="bg-home">
			<div className="container">
				<div className="pb-1">
					<div className="mt-4 mb-2 pb-2 fw-bold fs-1 text-center">
						All{' '}
						<span className=" text-primary ">Government Jobs</span>
						<br /> in One Place
					</div>
				</div>
				{children}
			</div>
		</div>
	);
};

export default SearchLayout;

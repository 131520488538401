import React, { useEffect, useState } from 'react';
import PageForm from '../../../components/PageForm';
import { useParams } from 'react-router-dom';
import { getPageById } from '../../../service/api';
import { getUserToken } from '../../../utils/auth';

const EditPage = () => {
	const { id } = useParams();
	const [page, setPage] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchPage = async () => {
				try {
					const response = await getPageById(id, {
						token: getUserToken(),
					});
					if (response.success) {
						setPage(response.data);
					}
				} catch (error) {
					console.log('Error fetching page:', error);
				}
			};
			fetchPage();
		}
	}, []);

	return <>{page && <PageForm page={page} />}</>;
};

export default EditPage;

import React from 'react';
import { DiscussionEmbed } from 'disqus-react';

const DisqusComments = ({ post }) => {
	const disqusShortname = 'sarkaar-dev';
	const disqusConfig = {
		url: `${process.env.REACT_APP_FRONTEND_URL}/job/${post.advertisementNumber}/${post._id}`,
		identifier: post._id,
		title: post.title,
	};

	return (
		<section className="section-box ">
			<div className="container">
				<div className="row card mx-5 my-5 py-3">
					<section className="align-items-center col-12">
						<DiscussionEmbed
							shortname={disqusShortname}
							config={disqusConfig}
						/>
					</section>
				</div>
			</div>
		</section>
	);
};

export default DisqusComments;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getJobsByAdNumber } from '../../service/api';
import moment from 'moment';
import DataTableComponent from '../../components/DataTable/DataTableComponent';

const JobArchivesPost = () => {
	const { jobAdNumber } = useParams();
	const [jobs, setJobs] = useState([]);

	const columns = [
		{
			name: 'Job Advertisement Number',
			selector: (row) => row.title,
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<p className="mb-0 fw-bold text-nowrap">
						{row.advertisementNumber}
					</p>
				</div>
			),
			sortable: false,
		},
		{
			name: 'Job Title',
			selector: (row) => row.title,
			sortable: true,
		},
		{
			name: 'Department',
			selector: (row) => row.department.name,
			sortable: true,
		},
		{
			name: 'Grade',
			selector: (row) => row.scale.replace('_', ' '),
			sortable: true,
		},
		{
			name: 'Last Date To Apply',
			selector: (row) =>
				moment(row.lastDateToApply).format('MMMM DD, YYYY'),
			sortable: true,
		},
		{
			name: 'More Details',
			cell: (row) => {
				const jobTitle = row.title ? row.title.replace(/ /g, '-') : '';
				const department = row.department?.name
					? row.department.name.replace(/ /g, '-')
					: '';
				const openingDate = row.jobOpeningDate
					? moment(row.jobOpeningDate)
							.format('DD-MMM-YYYY')
							.replace(/ /g, '-')
					: '';

				let jobUrl = `/job/${row.advertisementNumber}/${jobTitle}`;
				if (department) {
					jobUrl += `+${department}`;
				}
				if (openingDate) {
					jobUrl += `+${openingDate}`;
				}
				return (
					<div className="d-flex align-items-center justify-content-center">
						<Link
							to={jobUrl}
							className="text-nowrap"
							target="_blank"
						>
							View More Details
						</Link>
					</div>
				);
			},
			sortable: false,
		},
	];

	useEffect(() => {
		if (jobAdNumber) {
			const fetchJob = async () => {
				try {
					const response = await getJobsByAdNumber(jobAdNumber);
					if (response?.success) {
						setJobs(response.data);
					}
				} catch (error) {
					console.log('Error fetching job:', error);
				}
			};
			fetchJob();
		}
	}, []);

	return (
		<>
			<section className="my-5">
				<div className="bg-light-1 py-5">
					<div className="container">
						{jobs && jobs.length > 0 ? (
							<DataTableComponent columns={columns} data={jobs} />
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
};

export default JobArchivesPost;

import React, { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import FormikForm from './FormikForm/FormikForm';
import { addBlog, updateBlog } from '../service/api';

const BlogForm = ({ blog }) => {
	const navigate = useNavigate();
	const [imageBase64, setImageBase64] = useState('');

	const initialValues = {
		title: '',
		publishDate: '',
		publishedBy: '',
		blogImage: '',
		blogURL: '',
		blogDescription: '',
	};

	if (blog) {
		initialValues.title = blog.title;
		initialValues.publishDate = blog.publishDate
			? new Date(blog.publishDate).toISOString().split('T')[0]
			: '';
		initialValues.publishedBy = blog.publishedBy;
		initialValues.blogURL = blog.blogURL;
		initialValues.blogDescription = blog.blogDescription;
	}

	const formFields = [
		{
			type: 'text',
			label: 'Title',
			name: 'title',
			fieldSize: 'md',
		},
		{
			type: 'date',
			label: 'Publish Date',
			name: 'publishDate',
			fieldSize: 'md',
		},
		{
			type: 'text',
			label: 'Published By',
			name: 'publishedBy',
			fieldSize: 'md',
		},
		{
			type: 'file',
			label: `${blog ? 'Replace Image' : 'Blog Image'}`,
			name: 'blogImage',
			fieldSize: 'md',
			onChange: async (event) => {
				const file = event.currentTarget.files[0];
				if (file) {
					const reader = new FileReader();
					reader.onloadend = () => {
						setImageBase64(reader.result);
					};
					reader.readAsDataURL(file);
				}
			},
		},
		{
			type: 'text',
			label: 'Blog URL',
			name: 'blogURL',
			fieldSize: 'md',
		},
		{
			type: 'editor',
			label: 'Blog Description',
			name: 'blogDescription',
			fieldSize: 'md',
		},
	];

	const validationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required'),
		publishDate: Yup.date().required('Publish Date is required'),
		publishedBy: Yup.string().required('Published By is required'),
		blogImage: Yup.mixed(),
		blogURL: Yup.string().required('Blog URL is required'),
		blogDescription: Yup.string().required('Blog Description is required'),
	});

	const onSubmit = async (values, { resetForm }) => {
		const blogData = {
			title: values.title,
			publishDate: values.publishDate,
			publishedBy: values.publishedBy,
			blogImage: imageBase64,
			blogURL: values.blogURL,
			blogDescription: values.blogDescription,
		};

		try {
			if (blog) {
				await updateBlog(blog._id, blogData);
			} else {
				await addBlog(blogData);
			}
			resetForm(); // Clear the form after submission
			navigate('/admin/blogs');
		} catch (error) {
			console.log('Blog creation/update failed.', error);
		}
	};

	return (
		<div className="container mt-5">
			<div className="m-5 card rounded-lg shadow-lg row pt-4">
				<div className="login-header">
					<h4 className="text-center text-primary">
						{blog ? 'Edit Blog' : 'Add Blog'}
					</h4>
				</div>
				<hr className="mb-2" />
				<div className="card-body">
					<FormikForm
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						centerFields={false}
						selectedImages={[imageBase64]}
						fields={formFields}
					/>
				</div>
			</div>
		</div>
	);
};

export default BlogForm;
